import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  acceptTransaction,
  declineTransaction,
  errorHandler,
  getTransaction,
} from "../../api";
import InnerHeader from "../../components/Page/InnerHeader";
import InnerPage from "../../components/Page/InnerPage";
import { getClasses } from "../../constants/theme";
import Button from "../../components/Button";
import ClientInfo from "../../components/ClientInfo";
import PageData1 from "../../components/Data/PageData1";
import Statuses from "../../components/Statuses";
import moment from "moment";
import PageLineData from "../../components/Data/PageLineData";
import { beautifyAmount } from "../../utils/currency";
import { Settings } from "../../stores/Settings";
import TextArea from "../../components/Form/TextArea";
import { getFormCollector } from "../../stores/core/collector";
import AlertContainer from "../../components/Data/AlertContainer";

const useTransaction = (_id) => {
  const [tr, setTr] = useState({ _ready: false });

  useEffect(() => {
    getTransaction(_id)
      .then((res) => setTr({ ...res, _ready: true }))
      .catch((err) => errorHandler(err));
  }, []);

  return [
    tr,
    (state) => {
      setTr({ ...tr, ...state });
    },
  ];
};

export default function Transaction(props) {
  const classes = getClasses(dynamicStyle);
  const { id } = useParams();
  const [transaction, updateTransaction] = useTransaction(id);

  const accept = () => {
    Settings.emit("show_dialog", {
      title: "Подтвердить выводт средств",
      text: "После вашего подтверждения средства будут переведнеы пользователю на указанные им реквизиты.",
      acceptText: "Подтвердить",
      cancelText: "Отмена",
      onAccept: () => {
        acceptTransaction({ tr_id: id })
          .then((res) => {
            updateTransaction({
              status: res.status,
              local_accepted_at: res.local_accepted_at,
              local_accepted_by: res.local_accepted_by,
            });
          })
          .catch((err) => errorHandler(err));
      },
    });
  };

  const decline = () => {
    const form = getFormCollector({
      text: "",
    });

    Settings.emit("show_dialog", {
      title: "Отклонить вывод",
      text: "Вы действительно хотите отклонить вывод средств, не забудьте написать причину отклонения.",
      acceptText: "Отклонить",
      cancelText: "Отмена",
      Comment: (
        <div className={classes("commentWrapper")}>
          <TextArea
            collector={form}
            name={"text"}
            placeholder={"Причина отказа"}
          />
        </div>
      ),
      onAccept: () => {
        declineTransaction({ tr_id: id, comment: form.get("text") })
          .then((res) => {
            updateTransaction({
              status: res.status,
              local_declined_reason: res.local_declined_reason,
              local_declined_at: res.local_declined_at,
            });
          })
          .catch((err) => errorHandler(err));
      },
    });
  };

  return (
    <InnerPage
      ready={transaction._ready}
      Header={
        <InnerHeader
          goBack={true}
          breadcrumbs={[["Транзакции", "/transactions"], [`#${id}`]]}
        />
      }
      MainContent={
        <div className={classes("content")}>
          {transaction.local_declined_reason && (
            <AlertContainer
              marginBottom={24}
              title={"Транзакция отклонена, комментарий:"}
              value={transaction.local_declined_reason}
            />
          )}

          <PageData1
            data={[
              {
                title: "Сумма",
                value: beautifyAmount({
                  amount: transaction.action === 'deposit' ? transaction.amount :  transaction.amount + transaction.commission,
                  currency: transaction.currency,
                }),
              },
              transaction.action !== 'deposit' &&
              {
                title: "Коммисия",
                value: beautifyAmount({
                  amount: transaction.commission,
                  currency: transaction.currency,
                }),
              },
              transaction.action !== 'deposit' &&  {
                title: "Итог",
                value: beautifyAmount({
                  amount: transaction.amount,
                  currency: transaction.currency,
                }),
              },

              {
                title: "Действие",
                value: transaction.action === "deposit" ? "Оплата" : "Вывод",
              },
              {
                title: "Тип",
                value: transaction.external ? "Внешняя" : "Внутренняя",
              },
            ].filter((e) => !!e)}
          />

          <PageLineData
            title={"Статус транзакции"}
            value={
              <Statuses
                default={"NotSloved"}
                rowMode={false}
                class={"TRStatus"}
                status={transaction?.status}
              />
            }
          />

          {transaction.action === "deposit" && (
            <PageLineData
              title={"Покупка курса"}
              value={
                <Link
                  className={classes("link")}
                  to={`/study_groups/${transaction?.initiator?.sg_id?._id}`}
                >
                  {transaction?.initiator?.sg_id?.title}
                </Link>
              }
            />
          )}

          <PageData1
            data={[
              transaction?.created_at && {
                title: "Создания",
                value: moment(transaction?.created_at).format(
                  "HH:mm DD.MM.YYYY"
                ),
              },
              transaction?.local_accepted_at && {
                title: "Принята",
                value: moment(transaction?.local_accepted_at).format(
                  "HH:mm DD.MM.YYYY"
                ),
              },
              transaction?.local_declined_at && {
                title: "Отклонена",
                value: moment(transaction?.local_declined_at).format(
                  "HH:mm DD.MM.YYYY"
                ),
              },
              transaction?.accepted_at && {
                title: "Подтверждена",
                value: moment(transaction?.accepted_at).format(
                  "HH:mm DD.MM.YYYY"
                ),
              },
              transaction?.secured_at && {
                title: "Проведена",
                value: moment(transaction?.secured_at).format(
                  "HH:mm DD.MM.YYYY"
                ),
              },
            ].filter((e) => !!e)}
          />
        </div>
      }
      RightContent={
        <>
          <div className={classes("actions")}>
            {window.TRStatus.prototype.isWaitingForLocalConfirmation(
              transaction.status
            ) && (
              <>
                <Button
                  containerStyle={{ marginBottom: 8 }}
                  onClick={accept}
                  mode={"green"}
                  text={"Подтвердить"}
                />
                <Button
                  containerStyle={{ marginBottom: 8 }}
                  onClick={decline}
                  mode={"red"}
                  text={"Отклонить"}
                />
              </>
            )}
          </div>
          <ClientInfo client={transaction?.cl_id} />
        </>
      }
    />
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    content: {},
    commentWrapper: {
      marginTop: 16,
    },
    actions: {
      marginBottom: 16,
    },
  };
};
