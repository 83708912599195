import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { getClasses } from "../constants/theme";
import { Settings } from "../stores/Settings";
import { pureGet } from "../utils";
import InfinityList from "./InfinityList";

const calcCellSizes = (columns) => {
  const gc = [];

  Object.keys(columns).forEach((k) => {
    const f = columns[k].f || 1;
    gc.push(`${f}fr`);
  });

  return gc.join(` `);
};

export default React.forwardRef(function Table(props, ref) {
  const classes = getClasses(dynamicStyle);
  const [size, setSize] = useState(Settings.get("windowsize"));
  const columnCount = Object.keys(props.columns).length;
  const list = useRef();

  const gridTemplateColumns = useMemo(
    () => calcCellSizes(props.columns),
    [props.columns]
  );

  useImperativeHandle(ref, () => ({
    reload: () => {
      list.current.reload();
    },
  }));

  useEffect(() => {
    return Settings.on("windowsize", ({ next }) => {
      setSize(next);
    });
  }, []);

  const height = size.height - +(props.offset ?? 205);

  return (
    <div style={{ height: height }} className={classes("wrapper")}>
      <div
        style={{
          gridTemplateColumns: `${gridTemplateColumns}`,
        }}
        className={classes("headerRow")}
      >
        {Object.keys(props.columns || {}).map((k) => {
          return (
            <div key={k} className={classes("cell")}>
              {props.columns[k].title}
            </div>
          );
        })}
      </div>
      <div></div>
      <InfinityList
        ref={list}
        endComponent={props.endComponent}
        size={props.size}
        renderItem={(item, index, arr) => (
          <Row
            gridTemplateColumns={gridTemplateColumns}
            columnCount={columnCount}
            item={item}
            data={arr}
            index={index}
            columns={props.columns}
            rowClass={classes("row")}
            cellClass={classes("cell")}
          />
        )}
        fetchData={props.fetchData}
      />
    </div>
  );
});

const Row = (props) => {
  return (
    <div
      style={{
        gridTemplateColumns: props.gridTemplateColumns,
      }}
      className={props.rowClass}
    >
      {Object.keys(props.columns || {}).map((k) => {
        const pv = pureGet(props.item, k);

        let v =
          "converter" in props.columns[k]
            ? props.columns[k].converter(pv, props.item)
            : pv;

        return (
          <div key={k} className={props.cellClass}>
            {v}
          </div>
        );
      })}
    </div>
  );
};

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    headerRow: {
      paddingRight:40,
      ...Fonts.subhead2,
      display: "grid",
      "grid-template-columns": "subgrid",
      color: Colors.neutral1,
      paddingBottom: 16,
      borderBottom: "1px solid #E5EAEF",
      "grid-column-gap": "8px",
    },
    wrapper: {
      display: "grid",
      "grid-template-columns": "subgrid",
      overflowY:'scroll',
      alignContent: 'flex-start'
    },
    row: {
      paddingRight:40,
      ...Fonts.subhead1,
      display: "grid",
      "grid-template-columns": "subgrid",
      marginBottom: 8,
      paddingTop: 10,
      paddingBottom: 12,
      borderBottom: "1px solid #E5EAEF",
      "grid-column-gap": "8px",
    },
    cell: {
      // border: "1px solid ",
      display: "flex",
      flex: 1,
      alignItems: "center",
    },
  };
};
