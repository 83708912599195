import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import AuthPage from "./../pages/AuthPage/AuthPage";
import { errorHandler, getCurrentCounters, getMe } from "../api";
import { Settings } from "../stores/Settings";
import NotFound from "./../pages/NotFound/NotFound";
import Main from "../pages/Main";
import TransactionsList from "../pages/Transactions/TransactionsList";
import Transaction from "../pages/Transactions/Transaction";
import PretenstionList from "../pages/Pretension/PretenstionList";
import CoursesList from "../pages/Courses/CoursesList";
import StudyGroupsList from "../pages/StudyGroups/StudyGroupsList";
import ClientsList from "../pages/Clients/ClientsList";
import KYCList from "../pages/KYC/KYCList";
import ChatList from "../pages/Chats/ChatList";
import Pretension from "../pages/Pretension/Pretension";
import Course from "../pages/Courses/Course";
import Lesson from "../pages/Courses/Lesson";
import Hometask from "../pages/Courses/Hometask";
import KYC from "../pages/KYC/KYC";
import StartPage from "../pages/Courses/StartPage";
import Chat from "../pages/Chats/Chat";
import CreateChat from "../pages/Chats/CreateChat";
import NenKYCList from "../pages/KYC/NenKYCList";
import NewChatList from "../pages/Chats/NewChatList";
import NewPretenstionList from "../pages/Pretension/NewPretenstionList";
import NewTransactionsList from "../pages/Transactions/NewTransactionsList";
import NewCoursesList from "../pages/Courses/NewCoursesList";
import Client from "../pages/Clients/Client";
import EditLesson from "../pages/EditCourses/EditLesson";
import EditHometask from "../pages/EditCourses/EditHometask";
import EditStartPage from "../pages/EditCourses/EditStartPage";
import StudyGroup from "../pages/StudyGroups/StudyGroup";
import ArticlesList from "../pages/Articles/ArticlesList";
import CommentsList from "../pages/Comments/CommentsList";
import Articles from "../pages/Articles/Articles";
import EditSGStartPage from "../pages/StudyGroups/EditStartPage";
import EditArticle from "../pages/Articles/EditPage/EditArticle";

export default function Navigation() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/dasboard" element={<NotFound />} />

          <Route path="/transactions" element={<TransactionsList />} />
          <Route path="/new_transactions" element={<NewTransactionsList />} />
          <Route path="/transactions/:id" element={<Transaction />} />

          <Route path="/pretensions" element={<PretenstionList />} />
          <Route path="/new_pretensions" element={<NewPretenstionList />} />
          <Route path="/pretensions/:id" element={<Pretension />} />

          <Route path="/courses" element={<CoursesList />} />
          <Route path="/new_courses" element={<NewCoursesList />} />
          <Route path="/courses/:id" element={<Course />} />
          <Route path="/courses/:id/start" element={<StartPage />} />
          <Route path="/edit/courses/:id/start" element={<EditStartPage />} />


          
          <Route
            path="/courses/:id/lessons/:type/:index"
            element={<Lesson />}
          />

          <Route
            path="/edit/courses/:id/lessons/:type/:index"
            element={<EditLesson />}
          />

          <Route
            path="/courses/:id/lessons/:type/:index/hometask/:hindex"
            element={<Hometask />}
          />
         <Route
            path="/edit/courses/:id/lessons/:type/:index/hometask/:hindex"
            element={<EditHometask />}
          />



          <Route path="/study_groups" element={<StudyGroupsList />} />
          <Route path="/study_groups/:id" element={<StudyGroup />} />
          <Route path="/study_groups/:id/edit_start_page" element={<EditSGStartPage />} />

          


          <Route path="/clients" element={<ClientsList />} />
          <Route path="/clients/:id" element={<Client />} />

          <Route path="/kyc" element={<KYCList />} />
          <Route path="/new_kyc" element={<NenKYCList />} />
          <Route path="/kyc/:id" element={<KYC />} />

          <Route path="/chats" element={<ChatList />} />
          <Route path="/new_chats" element={<NewChatList />} />
          <Route path="/chats/:id" element={<Chat />} />
          <Route path="/chats/create/:id" element={<CreateChat />} />


          <Route path="/articles" element={<ArticlesList />} />
          <Route path="/articles/:id" element={<Articles />} />
          <Route path="/articles/:id/edit" element={<EditArticle />} />

          

          <Route path="/comments" element={<CommentsList />} />

          
          
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function Layout() {
  const location = useLocation();
  const [ready, setReady] = useState(undefined);

  useEffect(() => {
    if (Settings.has("authorizationToken")) {
      getMe()
        .then((res) => Settings.memorize("me", res))
        .then(() => getCurrentCounters())
        .then((counters) => {
          Settings.set("notify_counters", counters.data[0]);
          setReady(true);
        })
        .catch((err) => {
          if (err.exception === "SessionExpiredError") {
            Settings.clear("me");
            Settings.clear("authorizationToken");
          }
          errorHandler(err);
          setReady(false);
        });
    } else {
      setReady(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname === "/"]);

  if (ready === false) {
    return <AuthPage />;
  } else if (ready === true) {
    return <Main />;
  } else {
    return <NotFound />;
  }
}
