import React, { useImperativeHandle, useRef, useState } from "react";
import { getClasses } from "../constants/theme";

export default React.forwardRef(function Switch(props, ref) {
  const classes = getClasses(dynamicStyle);
  const element = useRef(void 0);
  const container = useRef(void 0);

  const [showed, setShoewd] = useState(false);

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }));

  const show = (el, event) => {
    element.current = el;
    const position = event.target.getBoundingClientRect();
    const { height, width } = window.document.body.getBoundingClientRect();

    console.log(height);

    let top;
    let bottom;
    let left;
    let right;

    if (height / 2 > position.top) {
      top = position.top + position.height;
    } else {
      bottom = height - position.bottom + position.height;
    }

    if (width/2  > position.left) {
      left = position.left;
    } else {
      right =width - position.right + position.width;
    }


    setShoewd({
      left,
      top,
      bottom,
      right
    });
  };

  const hide = () => {
    element.current = void 0;
    setShoewd(false);
  };

  const onItemPress = (item, e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    item.handler(element.current);
    hide();
  };

  if (!showed) return null;

  return (
    <>
      <div className={classes("overlay")} onClick={hide}>
        <div ref={container} className={classes("container")} style={showed}>
          {props.data.map((item, index, arr) => {
            return (
              <div
                onClick={(event) => onItemPress(item, event)}
                key={index + ""}
                // style={index === arr.length - 1 ? styles.last : styles.item}
                className={classes("item")}
              >
                {item.title}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
});

const dynamicStyle = (Colors, Fonts) => {
  return {
    last: {
      ...Fonts.subhead1,
      color: Colors.neutral1,
      paddingTop: 8,
      paddingBottom: 8,
      cursor: "pointer",
    },
    item: {
      ...Fonts.subhead2,
      color: Colors.neutral1,
      cursor: "pointer",
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16,
      paddingBottom: 12,
    },
    "item:hover": {
      backgroundColor: Colors.neutral4,
    },
    items: {},
    title: {
      ...Fonts.headline1,
      color: Colors.neutral1,
    },
    container: {
      position: "absolute",
      backgroundColor: Colors.backgroundLayout,
      minWidth: 375,
      borderRadius: 8,
      paddingTop: 12,
      paddingBottom: 12,
      filter:
        "drop-shadow(0px 16px 16px rgba(50, 50, 71, 0.16)) drop-shadow(0px 16px 32px rgba(50, 50, 71, 0.12))",
    },
    overlay: {
      position: "fixed",
      // backgroundColor: "#000000af",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 2,
    },
  };
};
