import React, { useState, useEffect } from "react";
import { useTheme } from "../constants/theme";
import { Settings } from "../stores/Settings";
import { X } from "react-feather";

export default function HelpSection(props) {
  const styles = useTheme(dynamicStyle);
  const [visible, setVisible] = useState(
    props.id ?  Settings.get(`help_notification.${props.id}`, true) : true
  );

  useEffect(() => {
    if (props.id === undefined) return;

    return Settings.on(`help_notification.${props.id}`, ({ next }) =>
      setVisible(next)
    );
  }, [props.id]);

  if (visible === false) return null;

  return (
    <div style={styles.container}>
      <div style={styles.wrapper}>
        <div style={{ flex: 1, display:'flex' }}>
          <div style={styles.text}>{props.children}</div>
        </div>
       {props.id &&  <div
          style={styles.iconWrapper}
          onClick={() => Settings.set(`help_notification.${props.id}`, false)}
        >
          <X style={styles.icon} />
        </div>}
      </div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts) => {
  return {
    iconWrapper: {
      height: 30,
      width: 40,
      alignItems: "flex-end",
    },
    icon: {
      color: Colors.color1,
      fontSize: 18,
    },
    text: {
      ...Fonts.subhead1,
      color: Colors.color1,
    },
    wrapper: {
      display: "flex",
      flexDirection: "row",
      padding: 16,
    },
    container: {
      backgroundColor: Colors.color4,
      borderRadius: 16,
      marginBottom: 8,
      marginTop:8
    },
  };
};
