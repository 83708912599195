import React from "react";
import ViewBlockAudio from "./blocks/ViewBlockAudio";
import ViewBlockAudioRecord from "./blocks/ViewBlockAudioRecord";
import ViewBlockBuiltInPDF from "./blocks/ViewBlockBuiltInPDF";
import ViewBlockHeader1 from "./blocks/ViewBlockHeader1";
import ViewBlockHeader2 from "./blocks/ViewBlockHeader2";
import ViewBlockHighlight from "./blocks/ViewBlockHighlight";
import ViewBlockHTML from "./blocks/ViewBlockHTML";
import ViewBlockPhoto from "./blocks/ViewBlockPhoto";
import ViewBlockText from "./blocks/ViewBlockText";
import ViewBlockVideo from "./blocks/ViewBlockVideo";

export default function BlockView({
  blocks = [],
  style,
  navigation,
  ...props
}) {
  return (
    <div style={style}>
      {blocks
        .filter((block) => block.value !== undefined)
        .map((block, index) => {
          switch (block.type) {
            case "html":
              return (
                <ViewBlockHTML
                  first={index === 0}
                  last={index === blocks.length - 1}
                  block={block}
                  key={index + ""}
                />
              );

            case "builtInPDF":
              return (
                <ViewBlockBuiltInPDF
                  first={index === 0}
                  last={index === blocks.length - 1}
                  block={block}
                  key={index + ""}
                />
              );
            case "header1":
              return (
                <ViewBlockHeader1
                  first={index === 0}
                  last={index === blocks.length - 1}
                  block={block}
                  key={index + ""}
                />
              );
            case "header2":
              return (
                <ViewBlockHeader2
                  first={index === 0}
                  last={index === blocks.length - 1}
                  block={block}
                  key={index + ""}
                />
              );
            case "text":
              return (
                <ViewBlockText
                  first={index === 0}
                  last={index === blocks.length - 1}
                  block={block}
                  key={index + ""}
                />
              );
            case "photo":
              return (
                <ViewBlockPhoto
                  first={index === 0}
                  last={index === blocks.length - 1}
                  block={block}
                  key={index + ""}
                />
              );
            case "highlight":
              return (
                <ViewBlockHighlight
                  first={index === 0}
                  last={index === blocks.length - 1}
                  block={block}
                  key={index + ""}
                />
              );
            case "video":
              return (
                <ViewBlockVideo
                  navigation={navigation}
                  block={block}
                  key={index + ""}
                  first={index === 0}
                  last={index === blocks.length - 1}
                  title={props.title}
                />
              );
            case "audio":
              return (
                <ViewBlockAudio
                  first={index === 0}
                  last={index === blocks.length - 1}
                  block={block}
                  key={index + ""}
                  title={props.title}
                />
              );
            case "audio_record":
              return (
                <ViewBlockAudioRecord
                  first={index === 0}
                  last={index === blocks.length - 1}
                  block={block.value}
                  key={index + ""}
                  title={props.title}
                />
              );

            default:
              return null;
          }
        })}
    </div>
  );
}
