import Joi from 'joi';
import {Loader} from '../stores/Loader';
import { getFormCollector } from "./../stores/core/collector";
import EventEmitter from "./../stores/core/emitter";

window.Joi = Joi;

const collector = getFormCollector(
  {
    _id: void 0,
    title: void 0,
    category: void 0,
    preview: [],
    blocks: [],
    _uv: void 0,
  },
  Joi.object({
    _id: Joi.any(),
    title: Joi.string().allow(''),
    category: Joi.string(),
    preview: Joi.array(),
    blocks: Joi.array(),
    _uv: Joi.any(),
    status: Joi.any(),
  }),
  (key, value, reset, force) => {
    Emitter.emit(key, value);
  },
);

export const Emitter = new EventEmitter();
export default collector;

window.createArticle = collector;

export const rehydrate = () => {
  const waitTaskCount = [];

  
  const blocks = collector.get('blocks');
  let blocksHasChanged = false;

  blocks.forEach(block => {
    if (filterfile(block)) {
      if (block?.value?.ready !== true && block?.value?.tmpid) {
        const task = Loader.get(`tasks.${block.value.tmpid}`);
        if (task?.ready) {
          Loader.clear(`tasks.${block.tmpid}`);
          block.value = task;
          blocksHasChanged = true;
        } else {
          waitTaskCount.push({
            section: 'blocks',
            block: block,
          });
        }
      }
    }
  });

  blocksHasChanged && collector.set('blocks', blocks);
  /////////

  const previewBlocks = collector.get('preview');
  let previewBlocksHasChanged = false;

  previewBlocks.forEach(block => {
    if (filterfile(block)) {
      if (block?.value?.ready !== true && block?.value?.tmpid) {
        const task = Loader.get(`tasks.${block.value.tmpid}`);
        if (task?.ready) {
          Loader.clear(`tasks.${block.tmpid}`);
          block.value = task;
          previewBlocksHasChanged = true;
        } else {
          waitTaskCount.push({
            section: 'preview',
            block: block,
          });
        }
      }
    }
  });

  previewBlocksHasChanged && collector.set('preview', previewBlocks);

  return waitTaskCount;

  // Проходимся по всем таскам и обновляем форму
  // на случай если таски зарезолвисись после размонтирования компонента формы
};





function filterfile(b) {
  return ['photo', 'video'].includes(b.type);
}