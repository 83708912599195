import { getClasses } from "../../constants/theme";

export default function AlertContainer(props) {
  const classes = getClasses(dynamicStyle);

  return (
    <div
      style={{ marginBottom: props.marginBottom }}
      className={classes("container", props.mode)}
    >
      {props.title && <div className={classes("title")}>{props.title}</div>}
      {props.value && <div className={classes("value")}>{props.value}</div>}
      {props.children && (
        <div className={classes("value")}>{props.children}</div>
      )}
    </div>
  );
}

AlertContainer.defaultProps = {
  marginBottom: void 0,
  mode: "red",
};

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    blue: {
        backgroundColor: Colors.blue10,
    },
    red: {
      backgroundColor: Colors.red10,
    },
    green: {
        backgroundColor: Colors.green10,
      },
    value: {
      ...Fonts.inputData,
      color: Colors.n90,
    },
    title: {
      ...Fonts.inputHeader,
      color: Colors.n70,
      marginBottom: 4,
    },
    container: {
      padding: "12px 48px 12px 16px",
      borderRadius: 8,
      marginBottom: 48,
    },
  };
};
