import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  errorHandler,
  getClientsChatMember,
  getTechClients,
  sendMessage,
} from "../api";
import InfinityList from "../components/InfinityList";
import { getClasses } from "../constants/theme";
import { Settings } from "../stores/Settings";

export default async function createChat(clId, navigate) {
  const res = await getClientsChatMember(clId);

  if (res.chm_id) {
    return navigate(`/chats/${res.chm_id._id}`);
  }

  Settings.emit("show_dialog", {
    title: "Выберите сотрудника тех доддержки",
    text: "От имени этого сотрудника в дальнейшем будет общение с клиентом",
    Comment: (
      <TechClientsList
        onSelected={async (client) => {
          Settings.emit("hide_dialog");

          const res = await sendMessage({
            text: "chat-created",
            attachments: [],
            target_type: "client",
            target_id: clId, // клиент
            cl_id: client._id, // клиента техподдержки
            system: true,
            muted: true,
          });

          return navigate(`/chats/${res.chm_id}`);
        }}
      />
    ),
  });
}

const TechClientsList = (props) => {
  const classes = getClasses(dynamicStyle);

  return (
    <div className={classes("wrapper")}>
      <InfinityList
        //endComponent={<div>Ещё</div>}
        disableAutoLoad={false}
        renderItem={(item, index, arr) => {
          return (
            <div
              onClick={() => props.onSelected(item)}
              className={classes("item")}
            >
              {item.nickname ?? `${item.first_name} ${item.last_name}`}
            </div>
          );
        }}
        fetchData={(params) =>
          getTechClients({
            ...params,
          })
        }
      />
    </div>
  );
};

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    wrapper: {
      maxHeight: 500,
      minHeight: 200,
      overflowY: "scroll",
      width: 500,
    },
    item: {
      height: 40,
      display: "flex",
      alignItems: "center",
      paddingLeft: 8,
      cursor: "pointer",
    },
    "item:hover": {
      backgroundColor: Colors.n30,
    },
  };
};
