import moment from "moment";
import React, { useMemo, useRef } from "react";
import { ArrowDown, ArrowUp } from "react-feather";
import { useSearchParams } from "react-router-dom";
import { getTransactions } from "../../api";
import Link from "../../components/Link";
import InnerHeader from "../../components/Page/InnerHeader";
import Table from "../../components/Table";
import TableEnd from "../../components/TableEnd";
import { getClasses } from "../../constants/theme";
import { beautifyAmount } from "../../utils/currency";
import RadioTabs from "./../../components/RadioTabs";

const getColumns = (classes) => ({
  _id: {
    f: 1,
    title: "ID",
    converter: (_id) => {
      return <Link to={`/transactions/${_id}`}>{_id}</Link>;
    },
  },
  action: {
    f: 0.4,
    title: "Тип",
    converter: (action) => {
      return action === "deposit" ? (
        <ArrowUp className={classes("arraowUp")} />
      ) : (
        <ArrowDown className={classes("arraowDown")} />
      );
    },
  },
  "initiator.sg_id.title": {
    f: 2,
    title: "Название курса",
  },
  cl_id: {
    f: 1.3,
    title: "Пользователь",
    converter: (cl) => {
      return (
        <Link to={`/clients/${cl._id}`}>
          {cl.nickname ?? `${cl.last_name} ${cl.first_name}`}
        </Link>
      );
    },
  },
  amount: {
    title: "Сумма",
    converter: (amount, item) => {
      if (item.action === 'withdraw'){
        return beautifyAmount({ amount: amount + item.commission , currency: item.currency });
      } else {
        return beautifyAmount({ amount: amount, currency: item.currency });
      }

    },
  },
  // status: {
  //   title: "Статус",
  //   converter: (status) => {
  //     return new window.TRStatus(status).toString().split("|").join(" ");
  //   },
  // },
  // withdraw_amount: {
  //   f: 1,
  //   title: "Сума вывода",
  //   converter: (amount, item) => {
  //     if (item.action === 'withdraw'){
  //       return beautifyAmount({ amount:item.amount, currency: item.currency });
  //     } else {
  //       return beautifyAmount({ amount, currency: item.currency });
  //     }

  //   },
  // },
  commission: {
    title: "Комисия",
    converter: (commission, item) => {
      if (item.action === 'withdraw'){
      return beautifyAmount({ amount:commission ?? item.withdraw_commission , currency: item.currency });
      } else {
        return '-'
      }
    },
  },
  created_at: {
    title: "Создана",
    converter: (created_at) => {
      return moment(created_at).format("DD.MM.YY HH:mm");
    },
  },
});

const omitUndefined = (obj) => {
  for (let k in obj) {
    if (obj[k] === undefined || obj[k] === null || obj[k] === "undefined") {
      delete obj[k];
    }
  }

  return obj;
};

export default function TransactionsList() {
  const classes = getClasses(dynamicStyle);
  const table = useRef();
  let [searchParams, setSearchParams] = useSearchParams();
  const columns = useMemo(() => getColumns(classes), []);
  const serachOptions = useRef({
    query: searchParams.get("query") ?? void 0,
    action: searchParams.get("action") ?? "all",
  });

  return (
    <div className={classes("wrapper")}>
      <InnerHeader
        defaultQuery={serachOptions.current.query}
        onQuerySubmit={(query) => {
          if (query) {
            serachOptions.current.query = query;
          } else {
            delete serachOptions.current.query;
          }

          setSearchParams(omitUndefined(serachOptions.current));

          table.current.reload();
        }}
        title={"Транзакции"}
      />
      <div className={classes("content")}>
        <div className={classes("filtersSection")}>
          <RadioTabs
            onChanged={(v) => {
              serachOptions.current.action = v;
              setSearchParams(omitUndefined(serachOptions.current));

              table.current.reload();
            }}
            default={serachOptions.current.action}
            items={[
              {
                value: "all",
                name: "Все",
              },
              {
                value: "deposit",
                name: "Покупка",
              },
              {
                value: "withdraw",
                name: "Вывод",
              },
            ]}
          />
        </div>
        <Table
          ref={table}
          endComponent={<TableEnd />}
          size={20}
          columns={columns}
          fetchData={(param) =>
            getTransactions({ ...param, ...serachOptions.current })
          }
        />
      </div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    filtersSection: {
      marginBottom: 32,
    },
    content: {
      paddingLeft: 40,
      paddingTop: 30,
    },
    searchItem: {
      marginBottom: 4,
    },
    searchLabel: {
      ...Fonts.subhead2,
      color: Colors.neutral1,
      marginBottom: 2,
    },
    arraowDown: {
      color: Colors.accent2,
    },
    arraowUp: {
      color: Colors.color1,
    },
    wrapper: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  };
};
