import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import {
  errorHandler,
  getStudyGroup,
  coursesСonsider,
  coursesReject,
  coursesConfirm,
  studyGroupIap,
} from "../../api";
import Button from "../../components/Button";
import PageData1 from "../../components/Data/PageData1";
import PageLineData from "../../components/Data/PageLineData";
import TextArea from "../../components/Form/TextArea";
import Title2 from "../../components/Layout/Title2";
import InnerHeader from "../../components/Page/InnerHeader";
import InnerPage from "../../components/Page/InnerPage";
import Statuses from "../../components/Statuses";
import { getClasses } from "../../constants/theme";
import { getFormCollector } from "../../stores/core/collector";
import { Settings } from "../../stores/Settings";
import { pureGet } from "../../utils";
import LessaonItem from "../CreateNewCourse/components/LessonItem";
import createChat from "../../methods/createChat";
import Section from "../../components/Layout/Section";
import ClientInfo from "../../components/ClientInfo";
import { beautifyAmount } from "../../utils/currency";

const useCourse = (_id) => {
  const [state, setState] = useState({ _ready: false });

  useEffect(() => {
    getStudyGroup(_id)
      .then((res) => setState({ ...res, ready: true }))
      .catch((err) => errorHandler(err));
  }, []);

  return [
    state,
    (status) => {
      setState({ ...state, status });
    },
    (status) => {
      setState({ ...state, apple_status: status });
    },
  ];
};

export default function StudyGroup(props) {
  const classes = getClasses(dynamicStyle);
  const { id } = useParams();
  const [studyGroup, updateStatus, updateAppleStatus] = useCourse(id);

  const navigate = useNavigate();

  const appleRepair = async () => {
    try {
      const res = await studyGroupIap(id);
    } catch (err){
      errorHandler(err)
    }
  };

  const goToLesson = (type, index) => {
    navigate(`/courses/${id}/lessons/${type}/${index}`);
  };

  return (
    <InnerPage
      ready={studyGroup._ready}
      Header={
        <InnerHeader breadcrumbs={[["Курсы", "/study_groups"], [`#${id}`]]} />
      }
      MainContent={
        <>
          <Section title={"Информация о курсе"}>
            <PageLineData title={"Название курса"} value={studyGroup.title} />

            <PageData1
              withoutMargin={true}
              data={[
                {
                  title: "Статус",
                  value: (
                    <Statuses
                      rowMode={true}
                      class={"SGStatus"}
                      status={studyGroup?.status}
                    />
                  ),
                },
                window.SGStatus.prototype.isPaid(studyGroup?.status) && {
                  title: "Статус",
                  value: (
                    <Statuses
                      rowMode={true}
                      class={"APLStatus"}
                      status={studyGroup?.apple_status}
                    />
                  ),
                },
                {
                  title: "ID курса",
                  value: studyGroup._id,
                },
                {
                  title: "ID шаблона",
                  value: (
                    <Link to={`/courses/${studyGroup.crs_id}`}>
                      {studyGroup.crs_id}
                    </Link>
                  ),
                },
                {
                  title: "Дата запуска",
                  value: moment(studyGroup.started_at).format(
                    "HH:mm DD.MM.YYYY"
                  ),
                },
              ]}
            />
          </Section>

          <Section title={"Дополнительно"}>
            <PageData1
              withoutMargin={false}
              data={[
                window.SGStatus.prototype.isPaid(studyGroup?.status) && {
                  title: "Стоимость",
                  value: beautifyAmount(studyGroup.course_price),
                },
                window.SGStatus.prototype.isPaid(studyGroup?.status) && {
                  title: "Коммисия сервиса",
                  value: studyGroup.course_price.commission_percent + "%",
                },
                window.SGStatus.prototype.isPaid(studyGroup?.status) && {
                  title: "Стоимость Apple",
                  value: beautifyAmount(studyGroup.apple_price),
                },
                {
                  title: "Тип курса",
                  value: studyGroup.course_type,
                },
              ]}
            />
          </Section>

          <Section title={"Статистика"}>
            <PageData1
              withoutMargin={false}
              data={[
                {
                  title: "Уник. просмотров",
                  value: studyGroup.unique_view_count,
                },
                {
                  title: "Просмотров",
                  value: studyGroup.view_count,
                },
                {
                  title: "Учеников",
                  value: studyGroup.member_count,
                },
                {
                  title: "Откл. заданий",
                  value: studyGroup.rejected_hometask_count,
                },
                {
                  title: "Отпр. заданий",
                  value: studyGroup.send_for_check_hometask_count,
                },
                {
                  title: "Не прочитаных сообщений",
                  value: studyGroup.total_unread_message_count,
                },
              ]}
            />
          </Section>

          <Section title={"Содержание курса"}>
            <div className={classes("lessons")}>
              <LessaonItem
                to={`/courses/${studyGroup.crs_id}/start`}
                title={"Страница курса"}
              />

              <LessaonItem
                title={pureGet(studyGroup, "introductory_lesson.0.title")}
                subtitle={`Вступительный урок`}
                to={`/courses/${studyGroup.crs_id}/lessons/introductory_lesson/0`}
              />

              {pureGet(studyGroup, "lessons", []).map((item, index) => {
                return (
                  <LessaonItem
                    title={item.title || "Нет названия"}
                    subtitle={`Урок ${index + 1}`}
                    to={`/courses/${studyGroup.crs_id}/lessons/lessons/${index}`}
                    item={item}
                    key={item.id}
                  />
                );
              })}

              <LessaonItem
                title={pureGet(studyGroup, "final_lesson.0.title")}
                subtitle={`Завершающий урок`}
                to={`/courses/${id}/lessons/final_lesson/0`}
              />
            </div>
          </Section>
        </>
      }
      RightContent={
        <>
          <div className={classes("actions")}>
            <Link to={`/study_groups/${id}/edit_start_page`}>
            <Button
                containerStyle={{ marginBottom: 8, textDecoration:'none' }}
                text={"Редатировать превью"}
                mode={"green"}
              />
              </Link>

            {!window.APLStatus.prototype.isIapCompleted(
              studyGroup?.apple_status
            ) && (
              <Button
                containerStyle={{ marginBottom: 8 }}
                onClick={() => appleRepair()}
                text={"Починить покупку"}
                mode={"red"}
              />
            )}
          </div>


          

          <ClientInfo client={studyGroup?.created_by} />
        </>
      }
    />
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    h2: {
      ...Fonts.h2,
      color: Colors.n90,
      marginBottom: 8,
    },
    buttonLink: {
      color: Colors.blue90,
      backgroundColor: Colors.blue10,
      ...Fonts.button,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: 45,
      borderRadius: 8,
      textDecoration: "none",
      marginBottom: 8,
      cursor: "pointer",
    },
    commentWrapper: {
      marginTop: 16,
    },
    actions: {
      marginBottom: 16,
    },
    subtitle: {
      ...Fonts.headline1,
      marginBottom: 8,
    },
    body: {
      flexDirection: "column",
      display: "flex",
      maxWidth: 500,
    },
    lessons: {
      flexDirection: "column",
      display: "flex",
      flex: 1,
    },

    description: {
      ...Fonts.subhead1,
      color: Colors.neutral1,
      marginBottom: 8,
    },
    backIcon: {
      color: Colors.content,
      marginRight: 16,
      cursor: "pointer",
    },
    title: {
      ...Fonts.title1,
      alignItems: "center",
      display: "flex",
      marginBottom: 16,
    },
  };
};
