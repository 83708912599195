import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  errorHandler,
  getChatMember,
  getChatMessages,
  getKYCEnity,
  readChat,
  rejectKYCEnity,
  sendToVerifyKYC,
  verifyKYCEnity,
} from "../../api";
import Button from "../../components/Button";
import PageData1 from "../../components/Data/PageData1";
import PageLineData from "../../components/Data/PageLineData";
import TextArea from "../../components/Form/TextArea";
import InfinityList from "../../components/InfinityList";
import Title2 from "../../components/Layout/Title2";
import InnerHeader from "../../components/Page/InnerHeader";
import InnerPage from "../../components/Page/InnerPage";
import Statuses from "../../components/Statuses";
import { getClasses } from "../../constants/theme";
import { getFormCollector } from "../../stores/core/collector";
import { Settings } from "../../stores/Settings";
import ChatInput from "./companents/ChatInput";
import Message from "./companents/Message";

const useChat = (_id) => {
  const [state, setState] = useState({ _ready: false });

  useEffect(() => {
    getChatMember(_id)
      .then((res) => setState({ ...res, _ready: true }))
      .catch((err) => errorHandler(err));
  }, []);

  return [state, (cb) => setState({ ...cb(state) })];
};

export default function Chat(props) {
  const classes = getClasses(dynamicStyle);
  const { id } = useParams();
  const [chatMember, updater] = useChat(id);
  const list = useRef();
  const page = useRef();

  useEffect(() => {
    const timer = setInterval(() => {
      const s = list.current.getState();
      list.current.getNext(
        s.data.length > 0 ? s.data[s.data.length - 1]._qp : void 0
      );
    }, 5000);

    // const scroll = page.current.getMainContentRef();

    // scroll.current.scrollTo(0, scroll.current.scrollHeight);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const onItemsAdded = ({ last = [], direction = 1 }) => {
    const scroll = page.current.getMainContentRef();

    if (
      scroll.current.offsetHeight + scroll.current.scrollTop >=
      scroll.current.scrollHeight - 100 * (last.length || 1)
    ) {
      scroll.current.scrollTo(0, scroll.current.scrollHeight);
    }

    if (direction === -1) {
      console.log(last);

      updater((state) => {
        state.support_client.unread_message_count += last.length;
        return state;
      });
    }
  };

  const read = async () => {
    try {
      await readChat(chatMember?.support_client?.chm_id);

      updater((state) => {
        state.support_client.unread_message_count = 0;
        return state;
      });
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <InnerPage
      ref={page}
      ready={chatMember._ready}
      Header={<InnerHeader breadcrumbs={[["Чаты", "/chats"], [`#${id}`]]} />}
      contentClass={classes("contentClass")}
      MainContent={
        <div className={classes("content")}>
          <div className={classes("listContainer")}>
            <div className={classes("list")}>
              <InfinityList
                onItemsAdded={onItemsAdded}
                ref={list}
                startComponent={<div>Ещё</div>}
                disableAutoLoad={true}
                invert={true}
                size={props.size}
                renderItem={(item, index, arr) => {
                  return (
                    <Message
                      isClient={
                        item?.created_by?._id === chatMember?.cl_id?._id
                      }
                      message={item}
                    />
                  );
                }}
                fetchData={(params) =>
                  getChatMessages({
                    direction: 1,
                    cht_id: chatMember?.cht_id?._id,
                    ...params,
                    size: 10,
                  })
                }
              />
            </div>
          </div>
          <ChatInput
            class={classes("inputContainer")}
            list={list}
            targetType={chatMember?.cht_id?.target_type}
            targetId={chatMember?.cht_id?.target_id}
            clId={chatMember?.cl_id?._id}
            chtId={chatMember?.cht_id?._id}
          />
        </div>
      }
      RightContent={
        <div className={classes("rightConent")}>
          {chatMember?.support_client?.unread_message_count > 0 && (
            <Button
              containerStyle={{ marginBottom: 8 }}
              text={"Отметить как прочитанные"}
              onClick={read}
              mode={"green"}
            />
          )}

          <Title2>Информация</Title2>
          <div style={{ marginBottom: 16 }}>
            <Link
              className={classes("buttonLink")}
              to={`/clients/${chatMember?.cl_id}`}
            >
              Перейти в профиль
            </Link>
          </div>

          <PageLineData
            title={"Имя пользователя"}
            value={
              chatMember?.cl_id?.nickname ??
              `${chatMember?.cl_id?.last_name} ${chatMember?.cl_id?.first_name}`
            }
          />
          <PageLineData
            title={"ID пользователя"}
            value={chatMember?.cl_id?._id}
          />
          <PageLineData
            title={"Статус пользователя"}
            value={
              <Statuses
                rowMode={true}
                class={"CLStatus"}
                status={chatMember?.cl_id?.status}
              />
            }
          />

          <PageLineData
            title={"Подписка"}
            value={chatMember?.cl_id?.subscription}
          />
          <PageLineData
            title={"Дата региcтрации"}
            value={moment(chatMember.registered_at).format("DD.MM.YYYY")}
          />

          <PageLineData
            title={"Подтвержденных шаблонов"}
            value={chatMember?.cl_id?.total_confirmed_course_count}
          />
          <PageLineData
            title={"Всего потоков"}
            value={chatMember?.cl_id?.total_study_group_count}
          />
          <PageLineData
            title={"Активных потоков"}
            value={chatMember?.cl_id?.total_active_study_group_count}
          />
        </div>
      }
    />
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    inputContainer: {
      position: "fixed",
      bottom: 0,
      right: 419,
      left: 332,
    },
    listContainer: {},
    list: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      justifyContent: "flex-end",
      marginBottom: 90,
    },
    rejectText: {
      color: Colors.red90,
    },
    commentWrapper: {
      marginTop: 16,
    },
    contentClass: {
      backgroundColor: `${Colors.n20}!important`,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },

    rightConent: {},
    buttonLink: {
      marginTop: -8,
      color: Colors.blue90,
      backgroundColor: Colors.blue10,
      ...Fonts.button,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: 45,
      borderRadius: 8,
      textDecoration: "none",
      marginBottom: 8,
    },
  };
};
