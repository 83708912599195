import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { errorHandler, getPretenstion, solvePretenstion } from "../../api";
import Button from "../../components/Button";
import ClientInfo from "../../components/ClientInfo";
import PageData1 from "../../components/Data/PageData1";
import InnerHeader from "../../components/Page/InnerHeader";
import InnerPage from "../../components/Page/InnerPage";
import Statuses from "../../components/Statuses";
import { getClasses } from "../../constants/theme";
import moment from "moment";
import PageLineData from "../../components/Data/PageLineData";
import AlertContainer from "../../components/Data/AlertContainer";
import { getFormCollector } from "../../stores/core/collector";
import { Settings } from "../../stores/Settings";
import TextArea from "../../components/Form/TextArea";
import { getClientTitle } from "../../utils";
import BlockView from "../../components/BlockView/BlockView";

const usePretension = (_id) => {
  const [state, setState] = useState({ _ready: false });

  useEffect(() => {
    getPretenstion(_id)
      .then((res) => setState({ ...res, _ready: true }))
      .catch((err) => errorHandler(err));
  }, []);

  return [
    state,
    (nState) => {
      setState({ ...state, ...nState });
    },
  ];
};

const getSubType = (target) => {
  switch (target) {
    case "study_group":
      return "Курс";
    case "study_group_review":
      return "Отзыв";
    case "want_ban_student":
      return "Студент";

    default:
      return "-";
  }
};

export default function Pretension(props) {
  const classes = getClasses(dynamicStyle);
  const { id } = useParams();
  const [pretension, updatePretension] = usePretension(id);

  const solve = () => {
    const form = getFormCollector({
      text: "",
    });

    Settings.emit("show_dialog", {
      title: "Проблема решена",
      text: "Напишишите коментарий как была решена проблема.",
      acceptText: "Отправить",
      Comment: (
        <div className={classes("commentWrapper")}>
          <TextArea
            collector={form}
            name={"text"}
            placeholder={"Комментарий"}
          />
        </div>
      ),
      onAccept: async () => {
        const comment = form.get("text");

        try {
          const res = await solvePretenstion({ comment, _id: id });
          updatePretension({
            status: res.status,
            solved_comment: res.solved_comment,
          });
        } catch (err) {
          errorHandler(err);
        }
      },
    });
  };
  return (
    <InnerPage
      ready={pretension._ready}
      Header={
        <InnerHeader
          breadcrumbs={[["Претензия", "/pretensions"], [`#${id}`]]}
        />
      }
      MainContent={
        <div className={classes("content")}>
          <PageData1
            data={[
              pretension.type !== "error" && {
                title: "Статус",
                value: (
                  <Statuses
                    default={"NotSloved"}
                    rowMode={true}
                    class={"PREStatus"}
                    status={pretension?.status}
                  />
                ),
              },
              {
                title: "Тип",
                value:
                  pretension.type === "pretension" ? "Обращение" : "Ошибка",
              },

              pretension.type === "pretension" && {
                title: "Таргет",
                value: getSubType(pretension.target_type),
              },
              {
                title: "ID претензии",
                value: pretension._id,
              },
              {
                title: "Дата создания",
                value: moment(pretension.created_at).format("HH:mm DD.MM.YYYY"),
              },
            ].filter((e) => !!e)}
          />

          {pretension.type === "error" && (
            <>
              <PageData1
                data={[
                  {
                    title: "name",
                    value: pretension.name,
                  },
                  {
                    title: "message",
                    value: pretension.message,
                  },
                ]}
              />
              <div className={classes("details")}>
                {Object.values(pretension?.details || []).map((d) => {
                  return (
                    <AlertContainer
                      marginBottom={16}
                      value={pretension.comment}
                    >
                      {JSON.stringify(d, null, "\t")}
                    </AlertContainer>
                  );
                })}
              </div>

              <textarea className={classes("stack")}>
                {pretension.stack}
              </textarea>
            </>
          )}

          {pretension.target_type === "want_ban_student" && (
            <>
              <PageLineData
                title={"Название курса"}
                value={
                  <Link
                    className={classes("link")}
                    to={`/study_groups/${pretension?.target_id?._id}`}
                  >
                    {pretension?.target_id?.sg_id?.title}
                  </Link>
                }
              />

              {pretension?.target_id?.cl_id ? (
                <PageLineData
                  title={"Жалоба на"}
                  value={
                    <Link
                      className={classes("link")}
                      to={`/clients/${pretension?.target_id?.cl_id?._id}`}
                    >
                      {getClientTitle(pretension?.target_id?.cl_id)}
                    </Link>
                  }
                />
              ) : (
                <PageLineData
                  title={"Жалоба на"}
                  value={
                    <Link
                      className={classes("link")}
                      to={`/clients/${pretension?.target_id?._id}`}
                    >
                      {getClientTitle(pretension?.target_id)}
                    </Link>
                  }
                />
              )}

              <AlertContainer
                marginBottom={24}
                title={"Жалоба"}
                value={pretension.comment}
              />

              <div className={classes("blocksWrapper")}>
                <div className={classes("blocks")}>
                  <BlockView blocks={pretension?.blocks} />
                </div>
              </div>
            </>
          )}

          {pretension.target_type === "study_group" && (
            <>
              <PageLineData
                title={"Название курса"}
                value={
                  <Link
                    className={classes("link")}
                    to={`/study_groups/${pretension?.target_id?._id}`}
                  >
                    {pretension?.target_id?.title}
                  </Link>
                }
              />
              <AlertContainer
                marginBottom={24}
                title={"Жалоба"}
                value={pretension.comment}
              />

              <div className={classes("blocksWrapper")}>
                <div className={classes("blocks")}>
                  <BlockView blocks={pretension?.blocks} />
                </div>
              </div>
            </>
          )}

          {pretension.target_type === "study_group_review" && (
            <>
              <PageLineData
                title={"Название курса"}
                value={
                  <Link
                    className={classes("link")}
                    to={`/study_groups/${pretension?.target_id?.sg_id?._id}`}
                  >
                    {pretension?.target_id?.sg_id?.title}
                  </Link>
                }
              />

              <AlertContainer
                marginBottom={24}
                title={"Отзыв"}
                mode={"blue"}
                value={pretension?.target_id?.review?.review}
              />

              <PageData1
                data={[
                  {
                    title: "Автор",
                    value: getClientTitle(pretension?.target_id?.cl_id),
                  },
                  {
                    title: "Оценка",
                    value: pretension?.target_id?.review?.rating,
                  },
                  {
                    title: "Создан",
                    value: moment(
                      pretension?.target_id?.review?.created_at
                    ).format("HH:mm DD.MM.YYYY"),
                  },
                ]}
              />

              <AlertContainer
                marginBottom={24}
                title={"Жалоба"}
                value={pretension.comment}
              />

              <div className={classes("blocksWrapper")}>
                <div className={classes("blocks")}>
                  <BlockView blocks={pretension?.blocks} />
                </div>
              </div>
            </>
          )}

          {window.PREStatus.prototype.isSolved(pretension.status) && (
            <>
              {pretension.solved_comment && (
                <AlertContainer
                  marginBottom={8}
                  title={"Решение"}
                  mode={"green"}
                  value={pretension.solved_comment}
                />
              )}
              <PageData1
                data={[
                  {
                    title: "Рещил",
                    value: pretension?.solved_by?._id
                      ? `${pretension?.solved_by?.first_name} ${pretension?.solved_by?.last_name}`
                      : "USR-system",
                  },
                  {
                    title: "Дата решения",
                    value: moment(pretension.solved_at).format(
                      "HH:mm DD.MM.YYYY"
                    ),
                  },
                ]}
              />
            </>
          )}
        </div>
      }
      RightContent={
        <>
          {!window.PREStatus.prototype.isSolved(pretension.status) &&
            pretension.type !== "error" && (
              <div className={classes("actions")}>
                <Button
                  containerStyle={{ marginBottom: 8 }}
                  onClick={solve}
                  mode={"green"}
                  text={"Проблема решена"}
                />
              </div>
            )}

          <div className={classes("h2")}>Создал</div>

          <ClientInfo client={pretension?.created_by} />
        </>
      }
    />
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    blocksWrapper: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
    },
    blocks: {
      width: 500,
    },
    commentWrapper: {
      marginTop: 16,
    },
    stack: {
      width: "100%",
      height: 300,
    },
    details: {
      whiteSpace: "pre-wrap",
    },
    link: {
      color: Colors.blue90,
      textDecoration: "none",
      ...Fonts.inputData,
    },
    h2: {
      ...Fonts.h2,
      color: Colors.n90,
      marginBottom: 8,
    },
    buttonLink: {
      color: Colors.blue90,
      backgroundColor: Colors.blue10,
      ...Fonts.button,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: 45,
      borderRadius: 8,
      textDecoration: "none",
      marginBottom: 8,
      cursor: "pointer",
    },
  };
};
