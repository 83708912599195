import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { errorHandler, getCourse } from "../../api";
import BlockView from "../../components/BlockView/BlockView";
import Title2 from "../../components/Layout/Title2";
import InnerHeader from "../../components/Page/InnerHeader";
import InnerPage from "../../components/Page/InnerPage";
import { getClasses } from "../../constants/theme";
import { pureGet } from "../../utils";
import CourseLessons from "./components/CourseLessons";

const useCourse = (_id) => {
  const [state, setState] = useState({});

  useEffect(() => {
    getCourse(_id)
      .then((res) => setState(res))
      .catch((err) => errorHandler(err));
  }, []);

  return [state];
};

export default function Hometask(props) {
  const classes = getClasses(dynamicStyle);
  const { id, type, index, hindex } = useParams();
  const [course] = useCourse(id);
  const lesson = pureGet(course, [type, index]);

  const title = { 
    lessons: `Урок ${+index + 1}`,
    final_lesson:'Завершающий урок',
    introductory_lesson:'Вступительный урок' 
  }[type];

  return (
    <InnerPage
      ready={course._ready}
      Header={
        <InnerHeader
          breadcrumbs={[
            ["Шаблоны", "/courses"],
            [`#${id}`, `/courses/${id}`],
            [title,  `/courses/${id}/lessons/lessons/${index}`],
            ['Задание']
          ]}
        />
      }
      MainContent={
        <div className={classes("wrapper")}>
          <div className={classes("content")}>
            <BlockView
              blocks={pureGet(lesson, ["hometasks", hindex, "blocks"])}
            />
          </div>
        </div>
      }
      RightContent={<CourseLessons course={course} />}
    />
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    wrapper: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
    },
    description: {
      color: Colors.n90,
      ...Fonts.tableData,
      marginBottom: 12,
    },
    content: {
      paddingLeft: 20,
      paddingRight: 40,
      display: "flex",
      width: 500,
      flexDirection: "column",
    },
  };
};
