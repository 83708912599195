import React, { useImperativeHandle, useRef } from "react";
import { getClasses } from "../../constants/theme";
import useWindowSize from "../../hooks/useWindowSize";
import Logo from "../Logo";
import loader from "./../../assets/load.gif";

function InnerPage(props, ref) {
  const mainContentRef = useRef();
  const classes = getClasses(dynamicStyle);
  const size = useWindowSize();
  const Header = props.Header;
  const MainContent = props.MainContent;
  const RightContent = props.RightContent;
  const showRightContent =
    RightContent &&
    (props.showRightContent === undefined ? true : props.showRightContent);
  const height = size.height - +(props.offset ?? 133);

  useImperativeHandle(ref, () => ({
    getMainContentRef: () => {
      return mainContentRef;
    },
  }));

  //if (!props.ready)
  // return <div className={classes('emptyContainer')}></div>

  return (
    <div className={classes("wrapper")}>
      {Header}
      <div className={classes("content", props.contentClass)}>
        {props.ready === false ? (
          <div className={classes("emptyContainer")}>
            <img className={classes("loader")} src={loader} />
          </div>
        ) : (
          <>
            <div
              ref={mainContentRef}
              style={{ height: height }}
              className={classes(
                "mainContent",
                showRightContent && "withSubContent"
              )}
            >
              {MainContent}
              <div style={{ height: 16 }}></div>
            </div>
            {showRightContent && (
              <div style={{ height: height }} className={classes("subContent")}>
                {RightContent}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default React.forwardRef(InnerPage);

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    loader: {
      height: 32,
      width: 32,
    },
    emptyContainer: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      right: 0,
      bottom: 0,
      top: 0,
    },
    subContent: {
      paddingTop: 30,
      borderLeft: "1px solid #E5EAEF",
      width: 340,
      paddingLeft: 24,
      paddingRight: 24,
      display: "flex",
      flexDirection: "column",
      right: "0",
      height: "100%",
      backgroundColor: Colors.n0,
      overflowY: "scroll",
    },
    mainContent: {
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 40,
      display: "flex",
      flex: 1,
      flexDirection: "column",
      overflowY: "scroll",
    },
    withSubContent: {},
    content: {
      paddingLeft: 20,
      display: "flex",
      backgroundColor: Colors.n0,
      flex: 1,
    },
    wrapper: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  };
};
