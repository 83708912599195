import { Link } from "react-router-dom";
import { getClasses } from "../../../constants/theme";

export default function LessaonItem(props) {
  const { onClick, title, subtitle } = props;
  const classes = getClasses(dynamicStyle);

  return (
    <Link
      to={props.to ?? "/"}
      className={classes(
        "container",
        props.hasError && "error",
        props.selected && "selected"
      )}
      onClick={onClick}
    >
      <div className={classes("title")}>{title}</div>
      <div className={classes("subtitle")}>{subtitle}</div>
    </Link>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    container: {
      marginTop: 8,
      backgroundColor: Colors.n20,
      borderRadius: 10,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 12,
      paddingBottom: 12,
      textDecoration: "none",
      color: Colors.n90,
    },
    "container:hover": {
      opacity: 0.5,
    },
    selected: {
      backgroundColor: Colors.blue10,
    },
    error: {
      outline: `2px solid ${Colors.accent2}`,
      backgroundColor: Colors.accent3,
    },
    title: {
      ...Fonts.subhead2,
      color: Colors.neutral1,
      marginBottom: 2,
    },
    subtitle: {
      ...Fonts.footnote,
      color: Colors.neutral2,
    },
  };
};
