import { getClasses } from "../../constants/theme";

export default function Title2({ children }) {
  const classes = getClasses(dynamicStyle);

  return <div className={classes("container")}>{children}</div>;
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    container: {
      ...Fonts.h2,
      color: Colors.n90,
      marginBottom:16
    },
  };
};
