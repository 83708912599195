import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorHandler, getCourse } from "../../api";
import BlockView from "../../components/BlockView/BlockView";
import Title2 from "../../components/Layout/Title2";
import InnerHeader from "../../components/Page/InnerHeader";
import InnerPage from "../../components/Page/InnerPage";
import { getClasses } from "../../constants/theme";
import { enrichCourse, findError2, pureGet } from "../../utils";
import collector from "./../../forms/createNewCourse";
import MainLesonBlock from "./../../components/BlockMaker/MainLesonBlock";

import {
  Emitter as courseEmiter,
  validateLesson,
} from "./../../forms/createNewCourse";
import TextInput from "../../components/Form/TextInput";
import FormErrorContainer from "../../components/FormErrorContainer";

const useCourse = (_id) => {
  const [state, setState] = useState({ _ready: false });
  useEffect(() => {
    getCourse(_id)
      .then((res) => {
        collector.reset(enrichCourse(res));
        setState({ ...res, _ready: true });
      })
      .catch((err) => errorHandler(err));
  }, []);

  useEffect(() => {
    if (!state._ready) return false;

    return courseEmiter.on("*", (a, b, c, d) => {
      setState({ ...enrichCourse(collector.get()), _ready: true });
    });
  }, [state._ready]);

  return [state];
};

export default function EditLesson(props) {
  const classes = getClasses(dynamicStyle);
  const { id, type, index } = useParams();
  const [course] = useCourse(id);
  const lesson = pureGet(course, [type, index]);
  const mainBlock = useRef();
  let valid = { error: null };

  if (course._ready) {
    valid = validateLesson(`${type}.${index}`);
  }

  const title = {
    lessons: `Урок ${+index + 1}`,
    final_lesson: "Завершающий урок",
    introductory_lesson: "Вступительный урок",
  }[type];

  return (
    <InnerPage
      ready={course._ready}
      Header={
        <InnerHeader
          breadcrumbs={[
            ["Шаблоны", "/courses"],
            [`#${id}`, `/courses/${id}`],
            [title],
            ["(редактирование)"],
          ]}
        />
      }
      MainContent={
        <div className={classes("wrapper2")}>
          <div className={classes("wrapper")}>
            <div className={classes("content")}>
              <Title2>Название урока</Title2>

              <TextInput
                containerStyle={{ backgroundColor: "none" }}
                className={classes("title")}
                name={`${type}.${index}.title`}
                collector={collector}
              />

              {findError2(valid, /title/) && (
                <FormErrorContainer>
                  Урок обязательно должен содержать заголовок
                </FormErrorContainer>
              )}

              {["value", "_id"].includes(
                findError2(valid, /^blocks/)?.context?.key
              ) && (
                <FormErrorContainer>
                  Значение блоков не должно быть пустым
                </FormErrorContainer>
              )}

              <Title2>Контент</Title2>

              <MainLesonBlock
                ref={mainBlock}
                showAddButton={true}
                buttonText={"Добавить блок"}
                form={collector}
                emitter={courseEmiter}
                formField={`${type}.${index}.blocks`}
              />
            </div>
          </div>

          <div className={classes("wrapper")}>
            <div className={classes("content")}>
              <Title2>Урок "{lesson?.title}"</Title2>

              <BlockView blocks={pureGet(lesson, "blocks", [])} />
            </div>
          </div>
        </div>
      }
      RightContent={null}
    />
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    title: {
      ...Fonts.h2,
      color: Colors.n90,
      marginBottom: 16,
    },
    wrapper: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
    },
    wrapper2: {
      display: "flex",
    },
    description: {
      color: Colors.n90,
      ...Fonts.tableData,
      marginBottom: 12,
    },
    content: {
      paddingLeft: 20,
      paddingRight: 40,
      display: "flex",
      width: 500,
      flexDirection: "column",
    },
  };
};
