//<Outlet />;

import Logo from "../../../components/Logo";
import { getClasses } from "../../../constants/theme";

export default function Header(props) {
  const classes = getClasses(dynamicStyle);

  return (
    <div className={classes("header")}>
      <Logo />
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
 
    header: {
      paddingLeft:32,
      paddingTop:24,
      paddingBottom:24,
      display: "flex",
      alignItems: "center",
    },
  };
};
