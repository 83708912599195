import {
    createFile,
    uploadBinaryFile,
    fileIsReady,
    errorHandler,
    removeFile,
  } from '../api';
  import {getUID, wait} from '../utils';
  import {Store} from './core/store';
  import {Settings} from './Settings';
  
  const MaxAttempCount = 12;
  
  export const Loader = new Store({
    key: 'store:loader-1',
    defaultSchema: {
      processed: false,
      tasks: {},
      queue: [],
    },
  });
  
  window.Loader = Loader;
  
  const getTask = (index = 0) => {
    const uuids = Loader.get('queue');
    let uuid = uuids[index];
  
    if (!uuid) {
      return [void 0, void 0];
    }
  
    let task = Loader.get(`tasks.${uuid}`);
  
    if (task?.error || task === undefined || task.ready) {
      return getTask(index + 1);
    }
  
    return [task, uuid];
  };
  
  const startLoad = async (force = false) => {
    if (Loader.get('processed') && force === false) {
      return;
    }
  
    const [task, uuid] = getTask();
  
    if (!uuid) {
      Loader.memorize('processed', false);
  
      return;
    }
  
    Loader.memorize('processed', true);
  
    if (task === undefined) {
      Loader.removeTasks([uuid]);
  
      return startLoad(true);
    }
  
    try {
      if (task._id === undefined) {
        const res = await createFile({
          content_type: task.content_type,
          prefix: task.prefix,
          options: task.options,
        });
  
        task._id = res._id;
        task.upload_url = res.upload_url;
  
        Loader.memorize(`tasks.${uuid}`, task);
      }
  
      if (task._id !== undefined && !task.loaded) {
       // const res = await uploadToGCS(task.upload_url, task.file, task.content_type)
        //console.log('uploadToGCS', res);
        const res = await uploadBinaryFile({
          upload_url: task.upload_url,
          content_type: task.content_type,
          file: task.file,
          onProgress: progress => {
            Loader.memorize(`tasks.${uuid}.progress`, progress);
          },
        });
  
        task.loaded = true;
  
        Loader.memorize(`tasks.${uuid}.loaded`, true);
      }
  
      if (task._id !== undefined && task.loaded) {
        const attemp = Loader.get(`tasks.${uuid}.attempt`, 0);
        const createdFile = await fileIsReady(task._id);
  
        if (createdFile.ready !== true) {
          if (attemp >= MaxAttempCount) {
            Loader.memorize(
              'queue',
              Loader.get('queue', []).filter(id => id !== task.tmpid),
            );
            return Loader.memorize(`tasks.${uuid}.error`, 'Max attemp count');
          }
  
          Loader.memorize(`tasks.${uuid}.attempt`, attemp + 1);
          return await wait(1000);
          //return startLoad(true);
        }
  
        Loader.memorize(`tasks.${uuid}`, {
          ...task,
          ready: true,
          filename: createdFile.filename,
          medium_filename: createdFile.medium_filename,
          status: createdFile.status,
        });
  
        Loader.memorize(
          'queue',
          Loader.get('queue', []).filter(id => id !== task.tmpid),
        );
      }
    } catch (e) {
      Loader.memorize(
        'queue',
        Loader.get('queue', []).filter(id => id !== uuid),
      );
      Loader.memorize(`tasks.${uuid}.error`, e);
      // Loader.removeTasks([uuid]);
      errorHandler(e);
    } finally {
      startLoad(true);
    }
  };
  
  Loader.load = (file, {prefix = 'CR', blockId, options = {}}) => {
    const url = file.path;
    const tmpid = getUID();
    const queue = Loader.get('queue', []);
    const lfile = {
      temp_url: url,
      file: file,
      _id: void 0,
      progress: 0,
      tmpid,
      blcok_id: blockId,
      content_type: file.type,
      prefix,
      ready: false,
      options: {
        ...options,
        size: file.size,
        width: file.width,
        height: file.height,
        duration: file.duration,
        filename: file.name,
      },
      attempt: 0,
    };
  
    queue.push(tmpid);
  
    Loader.memorize('queue', queue);
    Loader.memorize(`tasks.${tmpid}`, lfile);
  
    startLoad();
  
    return lfile;
  };
  
  Loader.start = startLoad;
  
  Loader.removeTasks = (tmpids = []) => {
    const queue = Loader.get('queue', []);
  
    Loader.memorize(
      'queue',
      queue.filter(id => !tmpids.includes(id)),
    );
  
    tmpids.forEach(id => Loader.clear(`tasks.${id}`));
  };
  
  Loader.removeWherReload = async tmpid => {
    const task = Loader.get(`tasks.${tmpid}`);
    Loader.removeTasks([tmpid]);
  
    if (task?._id) {
      try {
        if (task?._id !== undefined) {
          await removeFile(task?._id);
        }
      } catch (e) {
        errorHandler(e);
      }
    }
  };
  
  Loader.delete = async (tmpid, fileId) => {
    if (tmpid) {
      if (fileId === undefined) {
        fileId = Loader.get(`tasks.${tmpid}._id`);
      }
  
      Loader.clear(`tasks.${tmpid}`);
      const queue = Loader.get('queue', []);
  
      Loader.memorize(
        'queue',
        queue.filter(id => id !== tmpid),
      );
    }
  
    // try {
    //   if (fileId !== undefined) {
    //     await removeFile(fileId);
    //   }
    // } catch (e) {
    //   errorHandler(e);
    // }
  };
  
  Loader.on('tasks', opts => {});
  
  Settings.on('authorized', ({next, prev}) => {
    if (next) {
      startLoad();
    }
  });