import { getClasses } from "../../../constants/theme";

export default function ViewBlockHighlight({ block, first }) {
  const classes = getClasses(dynamicStyle);
  return (
    <div className={classes("constainer")}>
      <div className={classes("label")}>{block?.value}</div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts) => {
  return {
    constainer: {
      marginBottom: 8,
      backgroundColor: Colors.neutral4,
      borderRadius: 10,
    },
    label: {
      ...Fonts.subhead1,
      color: Colors.neutral1,
      paddingLeft: 16,
      paddingRight:16,
      paddingTop:12,
      paddingBottom:12,
      whiteSpace:'pre-wrap'
    },
  };
};
