import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  errorHandler,
  getKYCEnity,
  getKYCResult,
  rejectKYCEnity,
  sendToVerifyKYC,
  verifyKYCEnity,
  verifySelfEmployed,
} from "../../api";
import Button from "../../components/Button";
import AlertContainer from "../../components/Data/AlertContainer";
import PageData1 from "../../components/Data/PageData1";
import PageLineData from "../../components/Data/PageLineData";
import TextArea from "../../components/Form/TextArea";
import Section from "../../components/Layout/Section";
import InnerHeader from "../../components/Page/InnerHeader";
import InnerPage from "../../components/Page/InnerPage";
import Statuses from "../../components/Statuses";
import { getClasses } from "../../constants/theme";
import createChat from "../../methods/createChat";
import { getFormCollector } from "../../stores/core/collector";
import { Settings } from "../../stores/Settings";

const useKYC = (_id) => {
  const [state, setState] = useState({ _ready: false });

  useEffect(() => {
    getKYCEnity(_id)
      .then((res) => setState({ ...res, _ready: true }))
      .catch((err) => errorHandler(err));
  }, []);

  return [
    state,
    (newState) => {
      setState({ ...state, ...newState });
    },
  ];
};

export default function KYC(props) {
  const classes = getClasses(dynamicStyle);
  const { id } = useParams();
  const [kyc, updateKyc] = useKYC(id);
  const navigate = useNavigate();

  const confirm = async () => {
    Settings.emit("show_dialog", {
      title: "Принять документ",
      text: "После того как вы примите документ пользователь сможет создавать платные курсы.",
      acceptText: "Принять",
      cancelText: "Отмена",
      onAccept: async () => {
        try {
          const res = await verifyKYCEnity({ _id: kyc._id });
          updateKyc({ status: res.status });
        } catch (err) {
          errorHandler(err);
        }
      },
    });
  };

  const sendToVerify = async () => {
    Settings.emit("show_dialog", {
      title: "Отправить на проверку",
      text: "Вы уверены что хотите отправить документ на проверку",
      acceptText: "Принять",
      cancelText: "Отмена",
      onAccept: async () => {
        try {
          const res = await sendToVerifyKYC({ cl_id: kyc?.created_by?._id });
          updateKyc({
            status: res.status,
            rejected_remote_comment: res.rejected_remote_comment,
            rejected_remote_at: res.rejected_remote_at,
          });
        } catch (err) {
          errorHandler(err);
        }
      },
    });
  };

  const reject = async () => {
    const form = getFormCollector({
      text: "",
    });

    Settings.emit("show_dialog", {
      title: "Отклонить документ",
      text: "Опишите причину отказа, что бы пользователь мог внести правки и отправить его еще раз.",
      acceptText: "Отклонить",
      cancelText: "Отмена",
      Comment: (
        <div className={classes("commentWrapper")}>
          <TextArea
            collector={form}
            name={"text"}
            placeholder={"Причина отказа"}
          />
        </div>
      ),
      onAccept: async () => {
        try {
          const res = await rejectKYCEnity({
            _id: kyc._id,
            reject_comment: form.get("text"),
          });

          updateKyc({ status: res.status });
        } catch (err) {
          errorHandler(err);
        }
      },
    });
  };

  const getResult = async () => {
    try {
      const res = await getKYCResult(kyc?.created_by?._id);
      updateKyc({
        status: res.status,
        rejected_remote_comment: res.rejected_remote_comment,
        rejected_remote_at: res.rejected_remote_at,
      });
    } catch (err) {
      Settings.emit("show_dialog", {
        title: "Ошибка",
        Comment: (
          <div style={{ whiteSpace: "break-spaces" }}>
            {JSON.stringify(err, null, "\t")}
          </div>
        ),
        acceptText: "ok",
      });
    }
  };

  const checkSafeEmplode = async () => {
    try {
      const res = await verifySelfEmployed({ _id: kyc._id });

      updateKyc({ status: res.status });

      if (res?.result?.error){
        Settings.emit("local_notification", {
          text:res?.result?.error?.message,
          level:'red',
          title:'Проверка не пройдена'
        });
      } else {
        Settings.emit("local_notification", `Проверка пройдена`);
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <InnerPage
      ready={kyc._ready}
      Header={<InnerHeader breadcrumbs={[["Документы", "/kyc"], [`#${id}`]]} />}
      MainContent={
        <div className={classes("content")}>
          <Section title={"Заявка"}>
            <PageData1
              data={[
                {
                  title: "Тип",
                  value: (
                    <div className={classes("clientType")}>
                      {kyc?.created_by?.client_type}
                    </div>
                  ),
                },
                {
                  title: "Имя",
                  value: kyc.first_name,
                },
                {
                  title: "Фамилия",
                  value: kyc.surname,
                },
                {
                  title: "Отчество",
                  value: kyc.patronymic,
                },
                {
                  title: "Дата рождения",
                  value: moment(kyc.date_of_birth).format("DD.MM.YYYY"),
                },
              ]}
            />

            <PageLineData
              title={"Место рождения"}
              value={kyc?.place_of_birth}
            />

            <PageLineData
              title={"Адрес регистрации"}
              value={kyc?.registration_address}
            />
          </Section>

          <Section title={"Паспорт"}>
            <PageData1
              data={[
                {
                  title: "Код департамента",
                  value: kyc?.passport?.department_code,
                },
                {
                  title: "Когда выдан",
                  value: moment(kyc?.passport?.issued_at).format("DD.MM.YYYY"),
                },

                {
                  title: "Серия",
                  value: kyc?.passport?.series,
                },
                {
                  title: "Номер",
                  value: kyc?.passport?.number,
                },
              ]}
            ></PageData1>
            <PageLineData
              title={"Кем выдан"}
              value={kyc?.passport?.issued_by}
            />

            <PageData1
              data={[
                {
                  title: "Резидентство",
                  value: kyc.residency,
                },
                {
                  title: "ИНН",
                  value: kyc.itn,
                },
              ]}
            />
          </Section>
        </div>
      }
      RightContent={
        <div className={classes("rightConent")}>
          {!window.KYCStatus.prototype.isSelfEmployedVerified(kyc?.status) &&
          window.KYCStatus.prototype.isWaitingVerification(kyc?.status) &&
            kyc?.created_by?.client_type === "self_employed" && (
              <Button
                containerStyle={{ marginBottom: 8 }}
                text={"Проверить на самозанятого"}
                onClick={checkSafeEmplode}
                mode={"green"}
              />
            )}

          {(window.KYCStatus.prototype.isWaitingVerification(kyc?.status) &&
            !(
              window.KYCStatus.prototype.isWaitingRemoteVerification(
                kyc?.status
              ) ||
              window.KYCStatus.prototype.isVerifiedRemote(kyc?.status) ||
              window.KYCStatus.prototype.isRejectedRemote(kyc?.status)
            )) &&
              !window.KYCStatus.prototype.isVerified(kyc?.status) &&
              !window.KYCStatus.prototype.isRejected(kyc?.status) &&  (
                <>
                  <Button
                    containerStyle={{ marginBottom: 8 }}
                    text={"Отправить на проверку"}
                    mode={"blue"}
                    onClick={sendToVerify}
                  />
                </>
              )}

          {window.KYCStatus.prototype.isWaitingRemoteVerification(
            kyc?.status
          ) &&
            !(
              window.KYCStatus.prototype.isVerifiedRemote(kyc?.status) ||
              window.KYCStatus.prototype.isRejectedRemote(kyc?.status)
            ) && (
              <Button
                containerStyle={{ marginBottom: 8 }}
                text={"Получить результат"}
                onClick={getResult}
                mode={"blue"}
              />
            )}

          {(window.KYCStatus.prototype.isVerifiedRemote(kyc?.status) ||
            window.KYCStatus.prototype.isRejectedRemote(kyc?.status)) &&
            !(
              window.KYCStatus.prototype.isVerified(kyc?.status) ||
              window.KYCStatus.prototype.isRejected(kyc?.status)
            ) && (
              <Button
                containerStyle={{ marginBottom: 8 }}
                text={"Одобрить"}
                onClick={confirm}
                mode={"green"}
              />
            )}

          {window.KYCStatus.prototype.isWaitingVerification(kyc?.status) &&
            !(
              window.KYCStatus.prototype.isVerified(kyc?.status) ||
              window.KYCStatus.prototype.isRejected(kyc?.status)
            ) && (
              <Button
                containerStyle={{ marginBottom: 8 }}
                text={"Отклонить"}
                onClick={reject}
                mode={"red"}
              />
            )}

          <PageLineData
            link={`/clients/${kyc?.created_by?._id}`}
            title={"Клиент"}
            value={
              kyc?.created_by?.nickname ??
              `${kyc?.created_by?.last_name} ${kyc?.created_by?.first_name}`
            }
          />
          <div
            className={classes("buttonLink")}
            onClick={() => createChat(kyc?.created_by?._id, navigate)}
            to={`/clients/${kyc?.created_by?._id}`}
          >
            Написать
          </div>
          <PageLineData
            title={"ID пользователя"}
            value={kyc?.created_by?._id}
          />

          {!!kyc?.status && (
            <PageLineData
              title={"Статус KYC"}
              value={
                <Statuses
                  rowMode={true}
                  class={"KYCStatus"}
                  status={kyc?.status}
                />
              }
            />
          )}

          {window.KYCStatus.prototype.isRejected(kyc?.status) && (
            <>
              <PageLineData
                title={"Заявка отклонена"}
                value={moment(kyc?.rejected_at).format("HH:mm DD.MM.YYYY")}
              />

              <AlertContainer
                marginBottom={24}
                title={"Комментарий"}
                value={kyc.reject_comment}
              />
            </>
          )}
          {window.KYCStatus.prototype.isRejectedRemote(kyc?.status) && (
            <>
              <PageLineData
                title={"Отклонено сервисом"}
                value={moment(kyc?.rejected_remote_at).format(
                  "HH:mm DD.MM.YYYY"
                )}
              />

              <AlertContainer
                marginBottom={24}
                title={"Комментарий от сервиса"}
                value={kyc.rejected_remote_comment}
              />
            </>
          )}
          <PageLineData
            title={"Заявка создана"}
            value={moment(kyc?.created_at).format("HH:mm DD.MM.YYYY")}
          />
          <PageLineData
            title={"Последнее обновление"}
            value={moment(kyc?.updated_at).format("HH:mm DD.MM.YYYY")}
          />

          {window.KYCStatus.prototype.isVerifiedRemote(kyc?.status) && (
            <PageLineData
              title={"Удаленное подтверждение"}
              value={moment(kyc?.verified_remote_at).format("HH:mm DD.MM.YYYY")}
            />
          )}
        </div>
      }
    />
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    clientType: {
      color: Colors.color1,
    },
    buttonLink: {
      cursor: "pointer",
      color: Colors.blue90,
      backgroundColor: Colors.blue10,
      ...Fonts.button,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: 45,
      borderRadius: 8,
      textDecoration: "none",
      marginBottom: 8,
    },
    rejectText: {
      color: Colors.red90,
    },
    commentWrapper: {
      marginTop: 16,
    },
    content: {},
    rightConent: {},
  };
};
