import moment from "moment";
import React, { useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { getNewPretensions, getPretensions } from "../../api";
import Link from "../../components/Link";
import InnerHeader from "../../components/Page/InnerHeader";
import Statuses from "../../components/Statuses";
import Table from "../../components/Table";
import TableEnd from "../../components/TableEnd";
import { getClasses } from "../../constants/theme";
import { omitUndefined } from "../../utils";
import RadioTabs from "./../../components/RadioTabs";

const getColumns = (classes) => ({
  _id: {
    f: 1,
    title: "ID",
    converter: (_id) => {
      return <Link to={`/pretensions/${_id}`}>{_id}</Link>;
    },
  },
  target_type: {
    title: "Таргет",
    converter: (target) => {
      switch (target) {
        case "study_group":
          return 'Курс'
        case "study_group_review":
          return 'Отзыв'
        case "want_ban_student":
          return 'Студент'

        default:
          return "-";
      }
    },
  },
  _uv: {
    f: 1.3,
    title: "Обращение",
    converter: (uv, item) => {
      return (item.message || item.comment).substr(0, 20);
    },
  },
  status: {
    title: "Статус",
    converter: (status, item) => {
      if (item.type === 'error')
        return '-';
      return <Statuses rowMode={true}  default={'NotSloved'} status={status} class={'PREStatus'}  />
    },
  },

  
  created_by: {
    f: 1.3,
    title: "Пользователь",
    converter: (cl) => {
      return (
        <Link to={`/clients/${cl._id}`}>
          {cl.nickname ?? `${cl.last_name} ${cl.first_name}`}
        </Link>
      );
    },
  },
  type: {
    title: "Тип",
  },
  created_at: {
    title: "Создана",
    converter: (created_at) => {
      return moment(created_at).format("DD.MM.YY HH:mm");
    },
  },
});

export default function NewPretenstionList() {
  const classes = getClasses(dynamicStyle);
  const table = useRef();
  let [searchParams, setSearchParams] = useSearchParams();
  const columns = useMemo(() => getColumns(classes), []);
  const serachOptions = useRef({
    query: searchParams.get("query") ?? void 0,
    type: searchParams.get("type") ?? "pretension",
  });

  return (
    <div className={classes("wrapper")}>
      <InnerHeader
        defaultQuery={serachOptions.current.query}
        onQuerySubmit={(query) => {
          if (query) {
            serachOptions.current.query = query;
          } else {
            delete serachOptions.current.query;
          }

          setSearchParams(omitUndefined(serachOptions.current));

          table.current.reload();
        }}
        title={"Открытые претензии"}
      />
      <div className={classes("content")}>
        <Table
          ref={table}
          endComponent={<TableEnd />}
          size={20}
          columns={columns}
          fetchData={(param) =>
            getNewPretensions({ ...param, ...serachOptions.current })
          }
        />
      </div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    filtersSection: {
      marginBottom: 32,
    },
    content: {
      paddingLeft: 40,
      paddingTop: 30,
    },
    searchItem: {
      marginBottom: 4,
    },
    searchLabel: {
      ...Fonts.subhead2,
      color: Colors.neutral1,
      marginBottom: 2,
    },
    arraowDown: {
      color: Colors.accent2,
    },
    arraowUp: {
      color: Colors.color1,
    },
    wrapper: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  };
};
