import { getClasses } from "../constants/theme";
import { makeImageUrl } from "../utils";

export default function Avatar(props) {
  const classes = getClasses(dynamicStyle);

  const avatar = makeImageUrl(props?.clId?.img_id);

  const author =
    props?.clId?.nickname ??
    `${props?.clId?.first_name} ${props?.clId?.last_name}`;

  const hasImage = !!avatar;

  return (
    <div className={classes("body")}>
      {hasImage ? (
        <img className={classes("img")} src={avatar} />
      ) : (
        author
        .split(/\s+/)
          .splice(0, 2)
          .map((str) => str[0].toUpperCase())
      )}
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    img: {
      height: 42,
      width: 42,
    },
    body: {
      ...Fonts.inputData,
      height: 42,
      width: 42,
      backgroundColor: Colors.blue90,
      borderRadius: 21,
      color: Colors.n0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
    },
  };
};
