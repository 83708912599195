import { getClasses } from "../../constants/theme";

export default function Subhead2({ children }) {
  const classes = getClasses(dynamicStyle);

  return <div className={classes("container")}>{children}</div>;
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    container: {
      ...Fonts.subhead2,
      color: Colors.neutral1,
      marginBottom:24
    },
  };
};
