import { getClasses } from "../../constants/theme";
import Link from "../Link";

export default function PageLineData(props) {
  const classes = getClasses(dynamicStyle);

  return (
    <div className={classes("container")}>
      <div className={classes("itemTitle")}>{props.title}</div>
      <div className={classes("itemValue")}>
        {props.link ? <Link to={props.link}>{props.value}</Link> : props.value}
      </div>
    </div>
  );
}

//                   <Link to={item.link}>{item.value}</Link>

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    itemTitle: {
      ...Fonts.inputHeader,
      color: Colors.n70,
      marginBottom: 4,
    },
    itemValue: {
      ...Fonts.inputData,
      color: Colors.n90,
    },

    container: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 24,
    },
  };
};
