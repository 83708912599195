import { getClasses } from "../constants/theme";

function Button(props) {
  const classes = getClasses(dynamicStyle);

  return (
    <div
      className={classes("container", props.mode)}
      onClick={props.onClick}
      style={props.containerStyle || {}}
    >
      {props.icon}
      {props.text}
    </div>
  );
}

Button.defaultProps = {
  mode: "blue",
};

export default Button;

const dynamicStyle = (Colors, Fonts) => {
  return {
    container: {
      ...Fonts.headline1,
      color: Colors.white,
      display: "flex",
      justifyContent: "center",
      paddingTop: 16,
      paddingBottom: 16,
      borderRadius: 8,
      cursor: "pointer",
      paddingLeft:48,
      paddingRight:48
    },
    blue: {
      backgroundColor: Colors.blue90,
    },
    green: {
      backgroundColor: Colors.green90,
    },
    red: {
      backgroundColor: Colors.red10,
      color: Colors.red90,
    },
    lightBlue: {
      color: Colors.blue90,
      backgroundColor: Colors.blue10,
    },
  };
};
