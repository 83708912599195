import moment from "moment";
import React, { useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { getClients } from "../../api";
import Link from "../../components/Link";
import InnerHeader from "../../components/Page/InnerHeader";
import Statuses from "../../components/Statuses";
import Table from "../../components/Table";
import TableEnd from "../../components/TableEnd";
import { getClasses } from "../../constants/theme";
import { omitUndefined } from "../../utils";
import RadioTabs from "./../../components/RadioTabs";

const getColumns = (classes) => ({
  _id: {
    f: 1,
    title: "ID",
    converter: (_id) => {
      return <Link to={`/clients/${_id}`}>{_id}</Link>;
    },
  },
  created_by: {
    f: 1.3,
    title: "Пользователь",
    converter: (s,cl) => {
      return (
        <Link to={`/clients/${cl._id}`}>
          {cl.nickname ?? `${cl.last_name} ${cl.first_name}`}
        </Link>
      );
    },
  },
  subscription: {
    f: 0.6,
    title: "Подписка",
  },
  status: {
    f: 1.4,
    title: "Статус",
    converter: (status) => {
      return <Statuses rowMode={true} status={status} class={'CLStatus'}  />
    },
  },
  registered_at: {
    title: "Создана",
    converter: (registered_at) => {
      return moment(registered_at).format("DD.MM.YY HH:mm");
    },
  },
});

export default function ClientsList() {
  const classes = getClasses(dynamicStyle);
  const table = useRef();
  let [searchParams, setSearchParams] = useSearchParams();
  const columns = useMemo(() => getColumns(classes), []);
  const serachOptions = useRef({
    query: searchParams.get("query") ?? void 0,
    action: searchParams.get("action") ?? "all",
  });

  return (
    <div className={classes("wrapper")}>
      <InnerHeader
        defaultQuery={serachOptions.current.query}
        onQuerySubmit={(query) => {
          if (query) {
            serachOptions.current.query = query;
          } else {
            delete serachOptions.current.query;
          }

          setSearchParams(omitUndefined(serachOptions.current));

          table.current.reload();
        }}
        title={"Клиенты"}
      />
      <div className={classes("content")}>
        <div className={classes("filtersSection")}>
          <RadioTabs
            onChanged={(v) => {
              serachOptions.current.action = v;
              setSearchParams(omitUndefined(serachOptions.current));

              table.current.reload();
            }}
            default={"all"}
            items={[
              {
                value: "all",
                name: "Все",
              },
              {
                value: "error",
                name: "Верифицированные",
              }
            ]}
          />
        </div>
        <Table
          ref={table}
          endComponent={<TableEnd />}
          size={20}
          columns={columns}
          fetchData={(param) =>
            getClients({ ...param, ...serachOptions.current })
          }
        />
      </div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    filtersSection: {
      marginBottom: 32,
    },
    content: {
      paddingLeft: 40,
      paddingTop: 30,
    },
    searchItem: {
      marginBottom: 4,
    },
    searchLabel: {
      ...Fonts.subhead2,
      color: Colors.neutral1,
      marginBottom: 2,
    },
    arraowDown: {
      color: Colors.accent2,
    },
    arraowUp: {
      color: Colors.color1,
    },
    wrapper: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  };
};
