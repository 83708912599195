import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { errorHandler, getClientInfo } from "../api";
import { getClasses } from "../constants/theme";
import createChat from "../methods/createChat";
import { beautifyAmount } from "../utils/currency";
import PageLineData from "./Data/PageLineData";
import Statuses from "./Statuses";

const useClientInfo = (cl) => {
  const [state, setState] = useState({ _ready: false });

  useEffect(() => {
    if (!cl?._id)
      return [state]
    getClientInfo(cl._id)
      .then((res) => setState({ ...state, ...res, _ready: true }))
      .catch((err) => errorHandler(err));
  }, [cl?._id]);

  return [state];
};

export default function ClientInfo(props) {
  const classes = getClasses(dynamicStyle);
  const navigate = useNavigate();

  const [client] = useClientInfo(props?.client);

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <Link className={classes("buttonLink")} to={`/clients/${client?._id}`}>
          Перейти в профиль
        </Link>
        <div
          className={classes("buttonLink")}
          onClick={() => createChat(client?._id, navigate)}
        >
          Написать
        </div>
      </div>

      <PageLineData
        title={"Имя пользователя"}
        value={`${client?.last_name} ${client?.first_name} ${
          client?.nickname ? `(${client?.nickname})` : ""
        }`}
      />
      <PageLineData title={"ID пользователя"} value={client?._id} />
      <PageLineData
        title={"Статус пользователя"}
        value={
          <Statuses rowMode={true} class={"CLStatus"} status={client?.status} />
        }
      />

      <PageLineData title={"Подписка"} value={client?.subscription} />
      <PageLineData
        title={"Дата региcтрации"}
        value={moment(client.registered_at).format("DD.MM.YYYY")}
      />

      <PageLineData
        title={"Подтвержденных шаблонов"}
        value={client?.total_confirmed_course_count}
      />
      <PageLineData
        title={"Всего потоков"}
        value={client?.total_study_group_count}
      />
      <PageLineData
        title={"Активных потоков"}
        value={client?.total_active_study_group_count}
      />

      <PageLineData
        title={"Всего заработано"}
        value={beautifyAmount({
          amount: client?.total_withdraw_amount,
          currency: client?.currency,
        })}
      />
      <PageLineData
        title={"Комиссия"}
        value={beautifyAmount({
          amount: client?.total_withdraw_commission,
          currency: client?.currency,
        })}
      />
    </>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    h2: {
      ...Fonts.h2,
      color: Colors.n90,
      marginBottom: 8,
    },
    buttonLink: {
      color: Colors.blue90,
      backgroundColor: Colors.blue10,
      ...Fonts.button,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: 45,
      borderRadius: 8,
      textDecoration: "none",
      marginBottom: 8,
      cursor: "pointer",
    },
  };
};
