import moment from "moment";
import Avatar from "../../../components/Avatar";
import ViewBlockAudio from "../../../components/BlockView/blocks/ViewBlockAudio";
import ViewBlockPhoto from "../../../components/BlockView/blocks/ViewBlockPhoto";
import ViewBlockVideo from "../../../components/BlockView/blocks/ViewBlockVideo";
import { getClasses } from "../../../constants/theme";

export default function Message(props) {
  const classes = getClasses(dynamicStyle);
  const message = props.message ?? {};

  const author =
    message?.created_by?.nickname ??
    `${message?.created_by?.first_name} ${message?.created_by?.last_name}`;

  return (
    <>
      <div className={classes("message", !props.isClient && "adminMessage")}>
        {props.isClient && (
          <div className={classes("avatar")}>
            <Avatar clId={message?.created_by} />
          </div>
        )}

        <div className={classes("body", !props.isClient && "adminBody")}>
          <div className={classes("title")}>{author}</div>
          <div className={classes("text")}> {message.text}</div>
          {message.attachments.map((attach) => {
            if (attach.type === "photo") {
              return (
                <div key={attach.value._id}>
                  <ViewBlockPhoto block={attach} />
                </div>
              );
            } else if (attach.type === "video") {
              return (
                <div key={attach.value._id}>
                  <ViewBlockVideo block={attach} />
                </div>
              );
            } else if (attach.type === "audio_record") {
              return (
                <div key={attach.value._id}>
                  <ViewBlockAudio block={attach} />
                </div>
              );
            } else {
              return null;
            }
          })}
          <div className={classes("data")}>
            {" "}
            {moment(message.created_at).fromNow()}
          </div>
        </div>

        {!props.isClient && (
          <div className={classes("avatar")}>
            <Avatar clId={message?.created_by} />
          </div>
        )}
      </div>
    </>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    data: {
      display: "flex",
      justifyContent: "flex-end",
      ...Fonts.tag,
      color: Colors.green90,
    },
    text: {
      marginBottom: 4,
      ...Fonts.tableData,
      color: Colors.n90,
    },
    title: {
      marginBottom: 4,
      ...Fonts.mainMenu,
      color: Colors.green90,
    },
    avatar: {},
    message: {
      display: "flex",
      marginBottom: 12,
      alignItems: "flex-end",
    },
    body: {
      backgroundColor: Colors.n0,
      marginRight: 4,
      marginLeft: 4,
      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
      borderRadius: "10px 10px 10px 0px",
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 8,
      paddingBottom: 2,
    },
    adminBody: {
      backgroundColor: Colors.green10,
      borderRadius: "8px 8px 0px 8px",
    },
    adminMessage: {
      alignSelf: "self-end",
    },
  };
};
