import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import {
  errorHandler,
  getArticle,
  getCategories,
  updateArticle,
} from "../../../api";

import InnerHeader from "../../../components/Page/InnerHeader";
import InnerPage from "../../../components/Page/InnerPage";
import { getClasses } from "../../../constants/theme";
import Section from "../../../components/Layout/Section";
import BlockView from "../../../components/BlockView/BlockView";
import { pureGet, enrichArticle, blocksSerialize } from "../../../utils";
import Title2 from "../../../components/Layout/Title2";
import form, { Emitter } from "./../../../forms/createNewArticle";
import TextInput from "../../../components/Form/TextInput";
import SelectPicker from "../../../components/Form/SelectPicker";
import MainLesonBlock from "../../../components/BlockMaker/MainLesonBlock";
import Button from "../../../components/Button";
import Joi from "joi";
import { blockJoiPreSchema } from "../../../forms/base";
import { Settings } from "../../../stores/Settings";

const useArticle = (_id) => {
  const [state, setState] = useState({ _ready: false });

  useEffect(() => {
    Promise.all([getCategories(), getArticle(_id)])
      .then(([categories, res]) => {
        form.reset({
          ...enrichArticle(res),
          _categories: categories.categories,
        });
        setState({ ...res, _ready: true });
      })
      .catch((err) => errorHandler(err));
  }, [_id]);

  useEffect(() => {
    if (!state._ready) return false;

    return Emitter.on("*", (a, b, c, d) => {
      setState({ ...enrichArticle(form.get()), _ready: true });
    });
  }, [state._ready]);

  return [
    state,
    (status, st = {}) => {
      setState({ ...state, status, ...st });
    },
  ];
};

export const validInfo = () => {
  return form.validate2(
    false,
    Joi.object({
      title: Joi.string().required(),
      category: Joi.string().required(),
      preview: Joi.array().items(blockJoiPreSchema()).min(1),
      intro_blocks: Joi.array().items(blockJoiPreSchema()).min(1),
    }),
    { allowUnknown: true, abortEarly: false }
  );
};

export default function EditArticle(props) {
  const classes = getClasses(dynamicStyle);
  const { id } = useParams();
  const [article] = useArticle(id);

  const update = async () => {
    try {
      const err = validInfo();
      if (err.error === null) {
        await updateArticle({
          _id:  form.get("_id"),
          title: form.get("title"),
          category: form.get("category"),
          preview: blocksSerialize(form.get("preview"), []),
          blocks: blocksSerialize(form.get("blocks"), []),
        });

        Settings.emit("local_notification", {
          text: "Статья обновлена",
        });

        // update
      } else {
        Settings.emit("local_notification", {
          text: "Ошибка валидации",
          level: "red",
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <InnerPage
      ready={article._ready}
      Header={
        <InnerHeader breadcrumbs={[["Статьи", "/articles"], [`#${id}`]]} />
      }
      RightContent={
        <>
          <div className={classes("actions")}>
            <Button
              onClick={update}
              containerStyle={{ marginBottom: 8, textDecoration: "none" }}
              text={"Сохранить"}
              mode={"green"}
            />
          </div>
        </>
      }
      MainContent={
        <>
          <div className={classes("wrapper2")}>
            <div className={classes("wrapper")}>
              <div className={classes("content")}>
                <div className={classes("label")}>Название курса</div>
                <TextInput
                  containerStyle={{
                    marginBottom: 16,
                  }}
                  name={"title"}
                  collector={form}
                />

                <div className={classes("label")}>Категоия</div>

                <SelectPicker
                  containerStyle={{
                    marginBottom: 16,
                  }}
                  name={"category"}
                  collector={form}
                  title={"Категория"}
                  items={form.link("_categories", []).map((c) => {
                    return {
                      value: c.name,
                      label: c.localization.root,
                    };
                  })}
                />

                <div className={classes("label")}>Обложка</div>

                <MainLesonBlock
                  containerStyle={{
                    marginBottom: 16,
                  }}
                  buttonText={"Добавить обложку"}
                  form={form}
                  emitter={Emitter}
                  formField={"preview"}
                  blocks={["photo", "video"]}
                  formType={"createNewCourse"}
                  firstDisableAction={{ showDialog: true }}
                />

                <div className={classes("label")}>Контент</div>

                <MainLesonBlock
                  containerStyle={{
                    marginBottom: 16,
                  }}
                  buttonText={"Добавить блок"}
                  form={form}
                  emitter={Emitter}
                  formField={"blocks"}
                  formType={"createNewCourse"}
                />
              </div>
            </div>

            <div className={classes("wrapper")}>
              <div className={classes("content")}>
                <Section title={article?.title}>
                  <Title2>
                    {
                      form
                        .link("_categories", [])
                        .filter((c) => c.name === article.category)[0]
                        ?.localization?.root
                    }
                  </Title2>
                  <BlockView blocks={pureGet(article, "preview", [])} />
                  <BlockView blocks={pureGet(article, "blocks", [])} />
                </Section>
              </div>
            </div>
          </div>
        </>
      }
    />
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    actions: {
      marginBottom: 16,
    },
    title: {
      ...Fonts.h2,
      color: Colors.n90,
      marginBottom: 16,
    },
    wrapper: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
    },
    wrapper2: {
      display: "flex",
    },
    textInputontainerStyle: {
      marginBottom: 16,
    },
    description: {
      color: Colors.n90,
      ...Fonts.tableData,
      marginBottom: 12,
    },
    content: {
      paddingLeft: 20,
      paddingRight: 40,
      display: "flex",
      width: 500,
      flexDirection: "column",
    },
    label: {
      ...Fonts.subhead1,
      color: Colors.neutral2,
      marginBottom: 8,
    },
  };
};
