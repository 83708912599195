import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorHandler, getCourse, getStudyGroup, updateDescription } from "../../api";
import BlockView from "../../components/BlockView/BlockView";
import Title2 from "../../components/Layout/Title2";
import InnerHeader from "../../components/Page/InnerHeader";
import InnerPage from "../../components/Page/InnerPage";
import { getClasses } from "../../constants/theme";
import { blocksSerialize, enrichCourse, findError2, pureGet } from "../../utils";
import MainLesonBlock from "./../../components/BlockMaker/MainLesonBlock";

import TextInput from "../../components/Form/TextInput";
import FormErrorContainer from "../../components/FormErrorContainer";
import Subhead2 from "../../components/Layout/Subhead2";
import HTMLInput from "../../components/Form/HTMLInput";
import { getFormCollector } from "../../stores/core/collector";
import Joi from "joi";
import { blockJoiPreSchema } from "../../forms/base";
import EventEmitter from "./../../stores/core/emitter";
import Button from "../../components/Button";
import { Settings } from "../../stores/Settings";

const useCourse = (_id) => {
  const [state, setState] = useState({ _ready: false });
  useEffect(() => {
    getStudyGroup(_id)
      .then((res) => {
        collector.reset(enrichCourse(res));
        setState({ ...res, _ready: true });
      })
      .catch((err) => errorHandler(err));
  }, []);

  useEffect(() => {
    if (!state._ready) return false;

    return courseEmiter.on("*", (a, b, c, d) => {
      setState({ ...enrichCourse(collector.get()), _ready: true });
    });
  }, [state._ready]);

  return [state];
};

export const courseEmiter = new EventEmitter();

const collector = getFormCollector(
  {
    _id: void 0,
    title: void 0,
    description: void 0,
    intro_blocks: [],
  },
  Joi.object({
    _id: Joi.any(),
    title: Joi.string().required(),
    description: Joi.string().required(),
    intro_blocks: Joi.array().items(blockJoiPreSchema()),
  }),
  (key, value, reset, force) => {
    courseEmiter.emit(key, value);
  }
);

export const validInfo = () => {
  return collector.validate2(
    false,
    Joi.object({
      title: Joi.string().required(),
      description: Joi.string().required(),
      intro_blocks: Joi.array().items(blockJoiPreSchema()),
    }),
    { allowUnknown: true, abortEarly: false }
  );
};

export default function EditSGStartPage(props) {
  const classes = getClasses(dynamicStyle);
  const { id, type, index } = useParams();
  const [course] = useCourse(id);
  let valid = { error: null };

  if (course._ready) {
    valid = validInfo();
  }

  const update = async () => {
    try {
      const err = validInfo();
      if (err.error === null){

        Settings.emit("local_notification", {
          text: "Описание курса обновлено",
        });

        await updateDescription({
        _id:       collector.get('_id'),
        title:       collector.get('title'),
        description:  collector.get('description'),
        intro_blocks: blocksSerialize(collector.get('intro_blocks', [])),
      });

      } else {
        Settings.emit("local_notification", {
          text: "Ошибка валидации",
          level:'red'
        });
      }   
    } catch(err){
      errorHandler(err)
    }
  }

  return (
    <InnerPage
      ready={course._ready}
      Header={
        <InnerHeader
          breadcrumbs={[
            ["Курс", "/study_groups"],
            [`#${id}`, `/study_groups/${id}`],
            ['Описание курса'],
            ["(редактирование)"],
          ]}
        />
      }
      MainContent={
        <div className={classes("wrapper2")}>
          <div className={classes("wrapper")}>
            <div className={classes("content")}>
              <Subhead2>Название курса</Subhead2>
              <TextInput
                containerStyle={{ marginBottom: 24 }}
                name={"title"}
                collector={collector}
              />
              <Subhead2>Описание</Subhead2>

              <HTMLInput
                containerStyle={{ marginBottom: 24 }}
                name={"description"}
                collector={collector}
              />

              <Subhead2>Медиаматериалы</Subhead2>

              {findError2(valid, /intro_blocks/) && (
                <FormErrorContainer>
                  Значение блоков не должно быть пустым
                </FormErrorContainer>
              )}

              <MainLesonBlock
                buttonText={"Добавить медиаматериалы"}
                form={collector}
                emitter={courseEmiter}
                formField={"intro_blocks"}
                blocks={["photo", "video"]}
                formType={"createNewCourse"}
              />
            </div>
          </div>

          <div className={classes("wrapper")}>
            <div className={classes("content")}>
              <Title2>{course.title}</Title2>
              <div
                dangerouslySetInnerHTML={{ __html: course.description }}
                className={classes("description")}
              ></div>
              <BlockView blocks={course.intro_blocks} />
            </div>
          </div>

          <div>
          <Button
                containerStyle={{ marginBottom: 8 }}
                onClick={() => update()}
                text={"Сохранить"}
                mode={"green"}
              />

          </div>  
        </div>
      }
      RightContent={null}
    />
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    title: {
      ...Fonts.h2,
      color: Colors.n90,
      marginBottom: 16,
    },
    wrapper: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
    },
    wrapper2: {
      display: "flex",
    },
    description: {
      color: Colors.n90,
      ...Fonts.tableData,
      marginBottom: 12,
    },
    content: {
      paddingLeft: 20,
      paddingRight: 40,
      display: "flex",
      width: 500,
      flexDirection: "column",
    },
  };
};
