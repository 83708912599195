import React from "react";
import { getClasses } from "./../constants/theme/index";

export default function TableEnd(props) {
  const classes = getClasses(dynamicStyle);
  return (
    <div className={classes("container")}>
      Ещё
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    container: {
      marginTop:8,
      marginBottom:10,
      cursor: "pointer",
      backgroundColor:'#0560FD',
      padding: '8px 16px',
      ...Fonts.subhead1,
      color: Colors.white,
      borderRadius:24,
    },
  };
};
