import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { errorHandler, getCourse } from "../../api";
import BlockView from "../../components/BlockView/BlockView";
import Title2 from "../../components/Layout/Title2";
import InnerHeader from "../../components/Page/InnerHeader";
import InnerPage from "../../components/Page/InnerPage";
import { getClasses } from "../../constants/theme";
import { pureGet } from "../../utils";
import CourseLessons from "./components/CourseLessons";

const useCourse = (_id) => {
  const [state, setState] = useState({ _ready: false });

  useEffect(() => {
    getCourse(_id)
      .then((res) => setState({ ...res, _ready: true }))
      .catch((err) => errorHandler(err));
  }, []);

  return [state];
};

export default function StartPage(props) {
  const classes = getClasses(dynamicStyle);
  const { id } = useParams();
  const [course] = useCourse(id);

  return (
    <InnerPage
      ready={course._ready}
      Header={
        <InnerHeader
          breadcrumbs={[
            ["Шаблоны", "/courses"],
            [`#${id}`, `/courses/${id}`],
            [`Страница курса`],
          ]}
        />
      }
      MainContent={
        <div className={classes("wrapper")}>
          <div className={classes("content")}>
            <Title2>{course.title}</Title2>
            <div dangerouslySetInnerHTML={{__html: course.description}}  className={classes("description")}></div>
            <BlockView blocks={course.intro_blocks} />
          </div>
        </div>
      }
      RightContent={<CourseLessons course={course} />}
    />
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    wrapper: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
    },
    description: {
      color: Colors.n90,
      ...Fonts.tableData,
      marginBottom: 12,
    },
    content: {
      paddingLeft: 20,
      paddingRight: 40,
      display: "flex",
      width: 500,
      flexDirection: "column",
    },
  };
};
