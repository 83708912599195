import { useRef } from "react";
import { ArrowUp, Paperclip } from "react-feather";
import { errorHandler, sendMessage } from "../../../api";
import AddAttachments from "../../../components/AddAttachments";
import { getClasses } from "../../../constants/theme";
import { useFormCollectorWithState } from "../../../stores/core/collector";
import { getQp } from "../../../utils";

export default function ChatInput(props) {
  const sw = useRef();
  const classes = getClasses(dynamicStyle);
  const [form, state] = useFormCollectorWithState({
    text: "",
    attachments: [],
    loading: false,
    message: void 0,
  });

  const sendWirhAttach = async ({ text, attachments }) => {
    try {
      const data = form.get();

      const res = await sendMessage({
        text: text,
        attachments: attachments.map((attach) => {
          return {
            type: attach.type,
            value: attach.value._id,
          };
        }),
        message: data.message,
        target_type: props.targetType,
        target_id: props.targetId, // клиент
        cl_id: props.clId, // клиента техподдержки
        cht_id: props.chtId,
      });

      props.list.current.insertBefore(res);
      form.set("text", "");
      window.scrollTo(0, document.body.scrollHeight);
    } catch (err) {
      errorHandler(err);
    }
  };

  const send = async () => {
    try {
      const data = form.get();

      const res = await sendMessage({
        text: data.text,
        attachments: data.attachments.map((attach) => {
          return {
            type: attach.type,
            value: attach.value._id,
          };
        }),
        message: data.message,
        target_type: props.targetType,
        target_id: props.targetId, // клиент
        cl_id: props.clId, // клиента техподдержки
        cht_id: props.chtId,
      });

      res._qp = getQp(res, [
        {
          field: "created_at",
          order: -1,
        },
      ]);

      props.list.current.insertBefore(res);
      form.set("text", "");
      window.scrollTo(0, document.body.scrollHeight);
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <>
      <div className={props.class}>
        <div className={classes("container")}>
          <input
            value={state.text}
            onChange={(event) => form.set("text", event.target.value)}
            className={classes("input")}
            placeholder="Сообщение"
          />
          <Paperclip
            onClick={(e) =>
              sw.current.show({
                attachments: form.get("attachments"),
                text: form.get("text"),
              })
            }
            size={24}
            className={classes("attachIcon")}
          />
          <div onClick={send} className={classes("sendButton")}>
            <ArrowUp size={24} className={classes("sendIcon")} />
          </div>
        </div>
      </div>
      <AddAttachments onSendClick={sendWirhAttach} ref={sw} />
    </>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    sendButton: {
      backgroundColor: Colors.green90,
      display: "flex",
      alignItems: "center",
      borderRadius: 24,
      height: 32,
      width: 32,
      justifyContent: "center",
      marginLeft: 8,
      cursor: "pointer",
    },
    sendIcon: {
      color: Colors.n0,
    },
    attachIcon: {
      cursor: "pointer",
      color: Colors.green90,
    },
    input: {
      marginLeft: 22,
      marginRight: 12,
      display: "flex",
      flex: 1,
      ...Fonts.tableData,
      color: Colors.n90,
      outline: "none",
      border: "none",
    },

    "input::placeholder": {
      color: Colors.n60,
      ...Fonts.tableData,
    },

    container: {
      height: 60,
      backgroundColor: Colors.n0,
      marginLeft: -38,
      marginRight: -30,
      display: "flex",
      alignItems: "center",
      paddingRight: 22,
    },
  };
};
