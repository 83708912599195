import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import {
  errorHandler,
  getCourse,
  coursesСonsider,
  coursesReject,
  coursesConfirm,
} from "../../api";
import Button from "../../components/Button";
import PageData1 from "../../components/Data/PageData1";
import PageLineData from "../../components/Data/PageLineData";
import TextArea from "../../components/Form/TextArea";
import Title2 from "../../components/Layout/Title2";
import InnerHeader from "../../components/Page/InnerHeader";
import InnerPage from "../../components/Page/InnerPage";
import Statuses from "../../components/Statuses";
import { getClasses } from "../../constants/theme";
import { getFormCollector } from "../../stores/core/collector";
import { Settings } from "../../stores/Settings";
import { pureGet } from "../../utils";
import LessaonItem from "../CreateNewCourse/components/LessonItem";
import createChat from "../../methods/createChat";
import Section from "../../components/Layout/Section";

const useCourse = (_id) => {
  const [state, setState] = useState({ _ready: false });

  useEffect(() => {
    getCourse(_id)
      .then((res) => setState({ ...res, ready: true }))
      .catch((err) => errorHandler(err));
  }, []);

  return [
    state,
    (status) => {
      setState({ ...state, status });
    },
  ];
};

export default function Course(props) {
  const classes = getClasses(dynamicStyle);
  const { id } = useParams();
  const [course, updateStatus] = useCourse(id);

  const navigate = useNavigate();

  const goToLesson = (type, index) => {
    navigate(`/courses/${id}/lessons/${type}/${index}`);
  };

  const consider = async () => {
    try {
      const res = await coursesСonsider(id);
      updateStatus(res.status);
    } catch (err) {
      errorHandler(err);
    }
  };

  const reject = async (comment) => {
    try {
      const res = await coursesReject(id, comment);
      updateStatus(res.status);
    } catch (err) {
      errorHandler(err);
    }
  };

  const confirm = async () => {
    try {
      const res = await coursesConfirm(id);
      updateStatus(res.status);
    } catch (err) {
      errorHandler(err);
    }
  };

  const considerCourse = () => {
    Settings.emit("show_dialog", {
      title: "Шаблон на рассмотрении",
      text: "Вы действительно хотите взять этот шаблон на рассмотрение.",
      acceptText: "Взять",
      cancelText: "Отмена",
      onAccept: consider,
    });
  };

  const confirmCourse = () => {
    Settings.emit("show_dialog", {
      title: "Одобрить курс",
      text: "Вы действительно хотите одобрить этот шаблон. После одобоения пользователь сможет запускать курсы из этого шаблона",
      acceptText: "Одобрить",
      cancelText: "Отмена",
      onAccept: confirm,
    });
  };

  const rejectCourse = () => {
    const form = getFormCollector({
      text: "",
    });

    Settings.emit("show_dialog", {
      title: "Отклонить курс",
      text: "Вы действительно хотите отклонить этот шаблон, не забудьте написать причину отклонения, что бы пользователь мог внести корректировки.",
      acceptText: "Отклонить",
      cancelText: "Отмена",
      Comment: (
        <div className={classes("commentWrapper")}>
          <TextArea
            collector={form}
            name={"text"}
            placeholder={"Причина отказа"}
          />
        </div>
      ),
      onAccept: () => reject(form.get("text")),
    });
  };

  return (
    <InnerPage
      ready={course._ready}
      Header={
        <InnerHeader breadcrumbs={[["Шаблоны", "/courses"], [`#${id}`]]} />
      }
      MainContent={
        <>
          <Section title={"Информация о курсе"}>
            <PageLineData title={"Название курса"} value={course.title} />

            <PageData1
              withoutMargin={true}
              data={[
                {
                  title: "Статус",
                  value: (
                    <Statuses
                      rowMode={true}
                      class={"CRSStatus"}
                      status={course?.status}
                    />
                  ),
                },
                {
                  title: "ID шаблона",
                  value: course._id,
                },
                {
                  title: "Дата отправки",
                  value: moment(course.created_at).format("HH:mm DD.MM.YYYY"),
                },
              ]}
            />
          </Section>

          <Section title={"Содержание курса"}>
            <div className={classes("lessons")}>
              <LessaonItem
                to={`/courses/${id}/start`}
                title={"Страница курса"}
              />

              <LessaonItem
                title={pureGet(course, "introductory_lesson.0.title")}
                subtitle={`Вступительный урок`}
                to={`/courses/${id}/lessons/introductory_lesson/0`}
              />
              {pureGet(course, "lessons", []).map((item, index) => {
                return (
                  <LessaonItem
                    title={item.title || "Нет названия"}
                    subtitle={`Урок ${index + 1}`}
                    to={`/courses/${id}/lessons/lessons/${index}`}
                    item={item}
                    key={item.id}
                  />
                );
              })}
              <LessaonItem
                title={pureGet(course, "final_lesson.0.title")}
                subtitle={`Завершающий урок`}
                to={`/courses/${id}/lessons/final_lesson/0`}
              />
            </div>
          </Section>
        </>
      }
      RightContent={
        <>
          <div className={classes("actions")}>
            {window.CRSStatus.prototype.isPendingConsideration(
              course.status
            ) && (
              <Button
                containerStyle={{ marginBottom: 8 }}
                onClick={considerCourse}
                text={"Взять на рассмотрение"}
              />
            )}
            {window.CRSStatus.prototype.isUnderConsideration(course.status) && (
              <>
                <Button
                  containerStyle={{ marginBottom: 8 }}
                  onClick={confirmCourse}
                  text={"Одобрить курс"}
                />
                <Button
                  containerStyle={{
                    marginBottom: 8,
                    backgroundColor: "#d02c2c",
                  }}
                  onClick={rejectCourse}
                  text={"Отклонить курс"}
                />
              </>
            )}
          </div>
          <div className={classes("h2")}>Создал</div>
          <div style={{ marginBottom: 16 }}>
            <Link
              className={classes("buttonLink")}
              to={`/clients/${course?.created_by?._id}`}
            >
              Перейти в профиль
            </Link>
            <div
              className={classes("buttonLink")}
              onClick={() => createChat(course?.created_by?._id, navigate)}
            >
              Написать
            </div>
          </div>

          <PageLineData
            title={"Имя пользователя"}
            value={
              course?.created_by?.nickname ??
              `${course?.created_by?.last_name} ${course?.created_by?.first_name}`
            }
          />
          <PageLineData
            title={"ID пользователя"}
            value={course?.created_by?._id}
          />
          <PageLineData
            title={"Статус пользователя"}
            value={
              <Statuses
                rowMode={true}
                class={"CLStatus"}
                status={course?.created_by?.status}
              />
            }
          />

          <PageLineData
            title={"Подписка"}
            value={course?.created_by?.subscription}
          />
          <PageLineData
            title={"Дата региcтрации"}
            value={moment(course.registered_at).format("DD.MM.YYYY")}
          />

          <PageLineData
            title={"Подтвержденных шаблонов"}
            value={course?.created_by?.total_confirmed_course_count}
          />
          <PageLineData
            title={"Всего потоков"}
            value={course?.created_by?.total_study_group_count}
          />
          <PageLineData
            title={"Активных потоков"}
            value={course?.created_by?.total_active_study_group_count}
          />

          <PageLineData title={"Всего заработано"} value={"???"} />
          <PageLineData title={"Комиссия"} value={"???"} />
        </>
      }
    />
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    h2: {
      ...Fonts.h2,
      color: Colors.n90,
      marginBottom: 8,
    },
    buttonLink: {
      color: Colors.blue90,
      backgroundColor: Colors.blue10,
      ...Fonts.button,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: 45,
      borderRadius: 8,
      textDecoration: "none",
      marginBottom: 8,
      cursor: "pointer",
    },
    commentWrapper: {
      marginTop: 16,
    },
    actions: {
      marginBottom: 16,
    },
    subtitle: {
      ...Fonts.headline1,
      marginBottom: 8,
    },
    body: {
      flexDirection: "column",
      display: "flex",
      maxWidth: 500,
    },
    lessons: {
      flexDirection: "column",
      display: "flex",
      flex: 1,
    },

    description: {
      ...Fonts.subhead1,
      color: Colors.neutral1,
      marginBottom: 8,
    },
    backIcon: {
      color: Colors.content,
      marginRight: 16,
      cursor: "pointer",
    },
    title: {
      ...Fonts.title1,
      alignItems: "center",
      display: "flex",
      marginBottom: 16,
    },
  };
};
