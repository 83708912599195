import React, { useImperativeHandle, useRef } from "react";
import { X } from "react-feather";
import { getClasses } from "../constants/theme";
import { useFormCollectorWithState } from "../stores/core/collector";
import { Loader } from "../stores/Loader";
import { getUID } from "../utils";
import Button from "./Button";
import { PhotoLodaer } from "./Form/PhotoLodaer";
import { VideoLoader } from "./Form/VideoLoader";
import Switch from "./Switch";

export default React.forwardRef(function AddAttachments(props, ref) {
  const classes = getClasses(dynamicStyle);
  const sw = useRef();
  const [form, state] = useFormCollectorWithState({
    showed: false,
    text: "",
    attachments: [],
  });

  useImperativeHandle(ref, () => ({
    show: (data) => {
      form.transaction(function () {
        this.silentSet("showed", true);
        this.silentSet("text", data.text ?? []);
        this.silentSet("attachments", data.attachments ?? []);
      });
    },
  }));

  const addAttachments = (type) => {
    form.set("attachments", [
      { type, id: getUID(), value: {}, autoOpen: true },
      ...state.attachments,
    ]);
  };

  const updateFileStatus = (value, id) => {
    form.set(
      "attachments",
      state.attachments.map((attach) => {
        if (attach.id === id) {
          attach.value = value;
        }
        return attach;
      })
    );
  };

  const onItemRemoved = (item, blockId) => {
    form.set(
      "attachments",
      state.attachments.filter((attach) => attach.id !== blockId)
    );

    Loader.delete(item.tmpid, item._id);
  };

  const cancel = () => {
    form.reset();
  };

  const send = async () => {
    const allFileLoaded = state.attachments.every((item) => item?.value?.ready);

    if (!allFileLoaded) {
      return alert("Не все файлы загрузились");
    }

    await props.onSendClick(form.get());

    form.reset();
  };

  if (state.showed === false) return null;

  return (
    <div className={classes("container")}>
      <div className={classes("body")}>
        <div className={classes("inner")}>
          <div className={classes("title")}>
            Вложения <X size={40} />
          </div>
          <div className={classes("attachments")}>
            {state.attachments.map((attach, index) => {
              if (attach.type === "photo") {
                return (
                  <>
                    <PhotoLodaer
                      key={attach.id}
                      autoOpen={true}
                      image={attach.value}
                      blockId={attach.id}
                      onItemRemoved={onItemRemoved}
                      onComplete={(value) => updateFileStatus(value, attach.id)}
                    />
                    <div style={{ height: 10 }}></div>
                  </>
                );
              } else if (attach.type === "video") {
                return (
                  <>
                    <VideoLoader
                      key={attach.id}
                      autoOpen={true}
                      video={attach.value}
                      onItemRemoved={onItemRemoved}
                      blockId={attach.id}
                      onComplete={(value) => updateFileStatus(value, attach.id)}
                      Component={null}
                    />
                    <div style={{ height: 10 }}></div>
                  </>
                );
              }
            })}
          </div>
          <Button
            onClick={(e) => sw.current.show(void 0, e)}
            mode={"green"}
            text={"Загрузить"}
          />
          <div className={classes("inputWrapper")}>
            <input
              value={state.text}
              onChange={(event) => form.set("text", event.target.value)}
              placeholder={"Комментарий"}
              className={classes("input")}
            />
          </div>

          <div className={classes("buttonSection")}>
            <Button onClick={cancel} mode={"lightBlue"} text={"Отменить"} />
            <div style={{ width: 12 }}></div>
            <Button onClick={send} text={"Отправить"} />
          </div>
        </div>
      </div>
      <Switch
        ref={sw}
        data={[
          {
            title: "Картинка",
            handler: (item) => addAttachments("photo"),
          },

          {
            title: "Видео",
            handler: (item) => addAttachments("video"),
          },
        ].filter((e) => !!e)}
      />
    </div>
  );
});

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    attachWrapper: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    inputWrapper: {
      borderRadius: 8,
      display: "flex",
      overflow: "hidden",
      borderWidth: 1,
      border: "1px solid #BCC8D6",
      marginTop: 16,
      marginBottom: 40,
    },
    input: {
      display: "flex",
      height: 40,
      paddingLeft: 16,
      paddingRight: 16,
      flex: 1,
      borderWidth: 0,
      ...Fonts.inputData,
    },
    "input:focus-visible": {
      outline: "none",
    },
    attachments: {
      minHeight: 200,
      maxHeight: 456,
      overflowY: "scroll",
      paddingRight: 6,
    },
    buttonSection: {
      display: "flex",
      justifyContent: "flex-end",
    },
    title: {
      ...Fonts.pageTitle,
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
      marginBottom: 24,
    },
    inner: {
      display: "flex",
      flex: 1,
      marginLeft: 40,
      marginRight: 40,
      marginTop: 24,
      marginBottom: 40,
      flexDirection: "column",
    },
    body: {
      width: 500,
      backgroundColor: Colors.n0,
      borderRadius: 8,
    },
    container: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "#00000075",
      zIndex: 9999,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  };
};
