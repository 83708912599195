import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  errorHandler,
  getClientEnity,
  getClientInfo,
  getKYCEnity,
  getKYCResult,
  getSubscriptions,
  rejectKYCEnity,
  sendToVerifyKYC,
  updateSubscription,
  verifyKYCEnity,
} from "../../api";
import Button from "../../components/Button";
import AlertContainer from "../../components/Data/AlertContainer";
import PageData1 from "../../components/Data/PageData1";
import PageLineData from "../../components/Data/PageLineData";
import TextArea from "../../components/Form/TextArea";
import Section from "../../components/Layout/Section";
import InnerHeader from "../../components/Page/InnerHeader";
import InnerPage from "../../components/Page/InnerPage";
import Statuses from "../../components/Statuses";
import { getClasses } from "../../constants/theme";
import createChat from "../../methods/createChat";
import { getFormCollector } from "../../stores/core/collector";
import { Settings } from "../../stores/Settings";
import { beautifyAmount } from "../../utils/currency";

const useClient = (_id) => {
  const [state, setState] = useState({ _ready: false });

  useEffect(() => {
    Promise.all([getClientEnity(_id), getClientInfo(_id)])
      .then(([res, info]) => setState({ ...res, info, _ready: true }))
      .catch((err) => errorHandler(err));
  }, []);

  return [
    state,
    (newState) => {
      setState({ ...state, ...newState });
    },
  ];
};

export default function Client(props) {
  const classes = getClasses(dynamicStyle);
  const { id } = useParams();
  const [client, updateClient] = useClient(id);
  const navigate = useNavigate();

  const sendNewSubscription = async (subscription) => {
    const res = await updateSubscription({
      cl_id: client._id,
      subscription,
    });

    updateClient({
      subscription: res.subscription
    })

    Settings.emit("hide_dialog");
    Settings.emit('local_notification',`Подписка изменена на ${res.subscription}`)

  };

  const changeSubscription = async () => {
    const subscriptions = await getSubscriptions();

    Settings.emit("show_dialog", {
      title: "Изменение подписки пользователя",
      text: "Выбранная подписка из списка будет назначена немедленно",
      Comment: (
        <div className={classes("sWrapper")}>
          {Object.keys(subscriptions.subscription).map((key) => {
            const s = subscriptions.subscription[key];
            return (
              <div
                onClick={() => sendNewSubscription(key)}
                className={classes("sItem")}
                key={key}
              >
                {s.i18n || key}
              </div>
            );
          })}
        </div>
      ),
    });
  };

  return (
    <InnerPage
      ready={client._ready}
      Header={
        <InnerHeader breadcrumbs={[["Пользователь", "/clients"], [`#${id}`]]} />
      }
      MainContent={
        <div className={classes("content")}>
          <Section title={"Информация"}>
            <PageData1
              data={[
                {
                  title: "Имя",
                  value: client.first_name,
                },
                {
                  title: "Фамилия",
                  value: client.last_name,
                },
                {
                  title: "Никнейм",
                  value: client.nickname,
                },
                {
                  title: "Дата регистрации",
                  value: moment(client.registered_at).format("DD.MM.YYYY"),
                },
              ]}
            />
            <PageData1
              data={[
                {
                  title: "Номер телефона",
                  value: client.phone_number,
                },
                {
                  title: "Подписка",
                  value: (
                    <div
                      className={classes("link")}
                      onClick={changeSubscription}
                    >
                      {client.subscription}
                    </div>
                  ),
                },
                {
                  title: "Тип работы",
                  value: client.client_type,
                },
                {
                  title: "Страна",
                  value: client.country,
                },
              ]}
            />
            <PageLineData
              title={"Статус пользователя"}
              value={
                <Statuses
                  rowMode={false}
                  class={"CLStatus"}
                  status={client?.status}
                />
              }
            />
            <PageLineData title={"О пользователе"} value={client.about} />
          </Section>
        </div>
      }
      RightContent={
        <div className={classes("rightConent")}>
          <Section title={"Действия"}>
            <div
              className={classes("buttonLink")}
              onClick={() => createChat(client?._id, navigate)}
              to={`/clients/${client?._id}`}
            >
              Написать
            </div>
          </Section>

          <Section title={"Метрики"}>
            <PageLineData
              title={"Всего заработано"}
              value={beautifyAmount({
                amount: client?.info?.total_withdraw_amount,
                currency: client?.info?.currency,
              })}
            />
            <PageLineData
              title={"Комиссия"}
              value={beautifyAmount({
                amount: client?.info?.total_withdraw_commission,
                currency: client?.info?.currency,
              })}
            />
            <PageLineData
              title={"Активных бесплатных курсов"}
              value={client?.active_free_study_group_count}
            />
            <PageLineData
              title={"Активных платных курсов"}
              value={client?.active_paid_study_group_count}
            />
            <PageLineData
              title={"Всего курсов"}
              value={client?.total_active_study_group_count}
            />

            <PageLineData
              title={"Всего активных потоков"}
              value={client?.total_active_study_group_count}
            />
            <PageLineData
              title={"Подтвержденных шаблонов"}
              value={client?.total_confirmed_course_count}
            />
            <PageLineData
              title={"Всего шаблонов рассмотрено"}
              value={client?.total_considerated_course_count}
            />
            <PageLineData
              title={"Всего черновиков"}
              value={client?.total_draft_course_count}
            />
            <PageLineData
              title={"Всего запущено курсов"}
              value={client?.total_study_group_count}
            />
            <PageLineData
              title={"Ноперочитаных сообщений"}
              value={client?.total_unread_message_count}
            />
            <PageLineData
              title={"Непрочитаные нотификации (орг)"}
              value={client?.unread_org_activity_count}
            />
            <PageLineData
              title={"Непрочитаные нотификации (студент)"}
              value={client?.unread_student_activity_count}
            />
          </Section>
        </div>
      }
    />
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    sWrapper: {
      marginTop: 8,
    },
    sItem: {
      cursor: "pointer",
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 12,
      borderRadius: 4,
    },
    "sItem:hover": {
      backgroundColor: Colors.neutral4,
    },
    link: {
      color: Colors.color1,
      cursor: "pointer",
    },
    buttonLink: {
      cursor: "pointer",
      color: Colors.blue90,
      backgroundColor: Colors.blue10,
      ...Fonts.button,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: 45,
      borderRadius: 8,
      textDecoration: "none",
      marginBottom: 8,
    },
    rejectText: {
      color: Colors.red90,
    },
    commentWrapper: {
      marginTop: 16,
    },
    content: {},
    rightConent: {},
  };
};
