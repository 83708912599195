import { useEffect, useState } from "react";
import { Settings } from "../stores/Settings";

export default function useWindowSize() {
  const [size, setSize] = useState(Settings.get("windowsize"));

  useEffect(() => {
    return Settings.on("windowsize", ({ next }) => {
      setSize(next);
    });
  }, []);

  return size;
}
